import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaMemoBadgeDefaultVariants = {
  "size": "small",
  "colorTheme": "neutral"
}
const aromaMemoBadgeCompoundVariants = []

const aromaMemoBadgeSlotNames = [
  [
    "wrap",
    "aroma-memo-badge__wrap"
  ],
  [
    "description",
    "aroma-memo-badge__description"
  ],
  [
    "menuTrigger",
    "aroma-memo-badge__menuTrigger"
  ],
  [
    "datesWrap",
    "aroma-memo-badge__datesWrap"
  ],
  [
    "title",
    "aroma-memo-badge__title"
  ]
]
const aromaMemoBadgeSlotFns = /* @__PURE__ */ aromaMemoBadgeSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaMemoBadgeDefaultVariants, getSlotCompoundVariant(aromaMemoBadgeCompoundVariants, slotName))])

const aromaMemoBadgeFn = memo((props = {}) => {
  return Object.fromEntries(aromaMemoBadgeSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaMemoBadgeVariantKeys = [
  "size",
  "colorTheme",
  "warning",
  "disabled"
]
const getVariantProps = (variants) => ({ ...aromaMemoBadgeDefaultVariants, ...compact(variants) })

export const aromaMemoBadge = /* @__PURE__ */ Object.assign(aromaMemoBadgeFn, {
  __recipe__: false,
  __name__: 'aromaMemoBadge',
  raw: (props) => props,
  variantKeys: aromaMemoBadgeVariantKeys,
  variantMap: {
  "size": [
    "small",
    "large"
  ],
  "colorTheme": [
    "pink",
    "purple",
    "blue",
    "green",
    "yellow",
    "orange",
    "red",
    "neutral"
  ],
  "warning": [
    "true",
    "false"
  ],
  "disabled": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaMemoBadgeVariantKeys)
  },
  getVariantProps
})