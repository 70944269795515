import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaDraggableContainerFn = /* @__PURE__ */ createRecipe('aroma-draggable-container', {}, [])

const aromaDraggableContainerVariantMap = {}

const aromaDraggableContainerVariantKeys = Object.keys(aromaDraggableContainerVariantMap)

export const aromaDraggableContainer = /* @__PURE__ */ Object.assign(memo(aromaDraggableContainerFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaDraggableContainer',
  __getCompoundVariantCss__: aromaDraggableContainerFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaDraggableContainerVariantKeys,
  variantMap: aromaDraggableContainerVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaDraggableContainerVariantKeys)
  },
  getVariantProps: aromaDraggableContainerFn.getVariantProps,
})