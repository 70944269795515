import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaContextualIconDefaultVariants = {
  "size": "medium",
  "colorTheme": "neutral",
  "hierarchy": "bold"
}
const aromaContextualIconCompoundVariants = [
  {
    "hierarchy": "bolder",
    "colorTheme": "transparent",
    "css": {
      "svg": {
        "fill": "transparent"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "brand",
    "css": {
      "root": {
        "color": "content.brand.bold"
      },
      "svg": {
        "stroke": "border.brand-bold"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "brand",
    "css": {
      "root": {
        "color": "content.brand.bold",
        "background": "background.brand.subtle"
      },
      "svg": {
        "stroke": "background.brand.subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "brand",
    "css": {
      "root": {
        "background": "background.brand.bold"
      },
      "svg": {
        "stroke": "background.brand.bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "brand",
    "css": {
      "root": {
        "background": "background.brand.bold-hover"
      },
      "svg": {
        "stroke": "background.brand.bold-hover"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "ai",
    "css": {
      "root": {
        "& i": {
          "background": "content.ai",
          "color": "transparent",
          "backgroundClip": "text"
        }
      },
      "svg": {
        "fontSize": "63",
        "stroke": "border.decorative.purple"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "ai",
    "css": {
      "root": {
        "background": "background.ai.subtle",
        "& i": {
          "background": "content.ai",
          "color": "transparent",
          "backgroundClip": "text"
        }
      },
      "svg": {
        "stroke": "background.ai.bold"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "ai",
    "css": {
      "root": {
        "display": "none"
      },
      "svg": {
        "display": "none"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "ai",
    "css": {
      "root": {
        "background": "background.ai.bold"
      },
      "svg": {
        "stroke": "background.ai.bold"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "neutral",
    "css": {
      "root": {
        "color": "content.neutral.base.subtlest"
      },
      "svg": {
        "stroke": "border.neutral.bold"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "neutral",
    "css": {
      "root": {
        "color": "content.neutral.base.subtlest",
        "background": "background.decorative.neutral.base-subtle"
      },
      "svg": {
        "stroke": "background.decorative.neutral.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "neutral",
    "css": {
      "root": {
        "background": "background.decorative.neutral.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.neutral.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "neutral",
    "css": {
      "root": {
        "background": "background.decorative.neutral.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.neutral.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "red",
    "css": {
      "root": {
        "color": "content.decorative.red"
      },
      "svg": {
        "stroke": "border.decorative.red"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "red",
    "css": {
      "svg": {
        "stroke": "background.decorative.red.base-subtle"
      },
      "root": {
        "color": "content.decorative.red",
        "background": "background.decorative.red.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "red",
    "css": {
      "root": {
        "background": "background.decorative.red.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.red.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "red",
    "css": {
      "root": {
        "background": "background.decorative.red.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.red.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "orange",
    "css": {
      "root": {
        "color": "content.decorative.orange"
      },
      "svg": {
        "stroke": "border.decorative.orange"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "orange",
    "css": {
      "svg": {
        "stroke": "background.decorative.orange.base-subtle"
      },
      "root": {
        "color": "content.decorative.orange",
        "background": "background.decorative.orange.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "orange",
    "css": {
      "root": {
        "background": "background.decorative.orange.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.orange.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "orange",
    "css": {
      "root": {
        "background": "background.decorative.orange.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.orange.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "yellow",
    "css": {
      "root": {
        "color": "content.decorative.yellow"
      },
      "svg": {
        "stroke": "border.decorative.yellow"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "yellow",
    "css": {
      "svg": {
        "stroke": "background.decorative.yellow.base-subtle"
      },
      "root": {
        "color": "content.decorative.yellow",
        "background": "background.decorative.yellow.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "yellow",
    "css": {
      "root": {
        "background": "background.decorative.yellow.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.yellow.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "yellow",
    "css": {
      "root": {
        "background": "background.decorative.yellow.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.yellow.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "green",
    "css": {
      "root": {
        "color": "content.decorative.green"
      },
      "svg": {
        "stroke": "border.decorative.green"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "green",
    "css": {
      "svg": {
        "stroke": "background.decorative.green.base-subtle"
      },
      "root": {
        "color": "content.decorative.green",
        "background": "background.decorative.green.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "green",
    "css": {
      "root": {
        "background": "background.decorative.green.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.green.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "green",
    "css": {
      "root": {
        "background": "background.decorative.green.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.green.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "blue",
    "css": {
      "root": {
        "color": "content.decorative.blue"
      },
      "svg": {
        "stroke": "border.decorative.blue"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "blue",
    "css": {
      "svg": {
        "stroke": "background.decorative.blue.base-subtle"
      },
      "root": {
        "color": "content.decorative.blue",
        "background": "background.decorative.blue.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "blue",
    "css": {
      "root": {
        "background": "background.decorative.blue.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.blue.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "blue",
    "css": {
      "root": {
        "background": "background.decorative.blue.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.blue.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "purple",
    "css": {
      "root": {
        "color": "content.decorative.purple"
      },
      "svg": {
        "stroke": "border.decorative.purple"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "purple",
    "css": {
      "svg": {
        "stroke": "background.decorative.purple.base-subtle"
      },
      "root": {
        "color": "content.decorative.purple",
        "background": "background.decorative.purple.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "purple",
    "css": {
      "root": {
        "background": "background.decorative.purple.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.purple.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "purple",
    "css": {
      "root": {
        "background": "background.decorative.purple.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.purple.base-bolder"
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "pink",
    "css": {
      "root": {
        "color": "content.decorative.pink"
      },
      "svg": {
        "stroke": "border.decorative.pink"
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "pink",
    "css": {
      "svg": {
        "stroke": "background.decorative.pink.base-subtle"
      },
      "root": {
        "color": "content.decorative.pink",
        "background": "background.decorative.pink.base-subtle"
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "pink",
    "css": {
      "root": {
        "background": "background.decorative.pink.base-bold"
      },
      "svg": {
        "stroke": "background.decorative.pink.base-bold"
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "pink",
    "css": {
      "root": {
        "background": "background.decorative.pink.base-bolder"
      },
      "svg": {
        "stroke": "background.decorative.pink.base-bolder"
      }
    }
  }
]

const aromaContextualIconSlotNames = [
  [
    "root",
    "aroma-contextual-icon__root"
  ],
  [
    "svg",
    "aroma-contextual-icon__svg"
  ]
]
const aromaContextualIconSlotFns = /* @__PURE__ */ aromaContextualIconSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaContextualIconDefaultVariants, getSlotCompoundVariant(aromaContextualIconCompoundVariants, slotName))])

const aromaContextualIconFn = memo((props = {}) => {
  return Object.fromEntries(aromaContextualIconSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaContextualIconVariantKeys = [
  "size",
  "colorTheme",
  "hierarchy"
]
const getVariantProps = (variants) => ({ ...aromaContextualIconDefaultVariants, ...compact(variants) })

export const aromaContextualIcon = /* @__PURE__ */ Object.assign(aromaContextualIconFn, {
  __recipe__: false,
  __name__: 'aromaContextualIcon',
  raw: (props) => props,
  variantKeys: aromaContextualIconVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium",
    "large",
    "xlarge",
    "jumbo"
  ],
  "colorTheme": [
    "brand",
    "neutral",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "pink",
    "ai",
    "transparent"
  ],
  "hierarchy": [
    "subtlest",
    "subtle",
    "bold",
    "bolder"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaContextualIconVariantKeys)
  },
  getVariantProps
})