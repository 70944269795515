import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaTagDefaultVariants = {
  "size": "small"
}
const aromaTagCompoundVariants = [
  {
    "hierarchy": "subtlest",
    "colorTheme": "reversed",
    "css": {
      "container": {
        "_withinWhiteLabelTheme": {
          "bgColor": "transparent",
          "--shadow-border-color": "border.neutral-on-brand.subtle",
          "color": "content.neutral.subtle"
        }
      },
      "removeTrigger": {
        "_withinWhiteLabelTheme": {
          "_hover": {
            "bgColor": "background.neutral-on-brand.base.subtler-hover"
          }
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "brand",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.brand-bold",
        "color": "content.brand.bold",
        "_withinWhiteLabelTheme": {
          "--shadow-border-color": "colors.border.brand.brand-brand",
          "color": "content.brand.subtle"
        }
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.brand.subtle-hover"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "neutral",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.neutral",
        "color": "content.neutral.base.subtle",
        "_withinWhiteLabelTheme": {
          "--shadow-border-color": "colors.border.neutral.bold"
        }
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.neutral.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "red",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.red",
        "color": "content.decorative.red"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.red.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "orange",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.orange",
        "color": "content.decorative.orange"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.orange.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "yellow",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.yellow",
        "color": "content.decorative.yellow"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.yellow.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "green",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.green",
        "color": "content.decorative.green"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.green.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "blue",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.blue",
        "color": "content.decorative.blue"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.blue.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "purple",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.purple",
        "color": "content.decorative.purple"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.purple.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtlest",
    "colorTheme": "pink",
    "css": {
      "container": {
        "--shadow-border-color": "colors.border.decorative.pink",
        "color": "content.decorative.pink"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.pink.hover-subtle"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "reversed",
    "css": {
      "container": {
        "_withinWhiteLabelTheme": {
          "backgroundColor": "background.neutral-on-brand.base.subtle",
          "color": "content.neutral.bold"
        }
      },
      "removeTrigger": {
        "color": "inherit",
        "_hover": {
          "bgColor": "background.brand.bold"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "brand",
    "css": {
      "container": {
        "backgroundColor": "background.brand.subtle",
        "color": "content.brand.bold",
        "_withinWhiteLabelTheme": {
          "color": "content.brand.subtle"
        }
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.brand.bold"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "neutral",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.neutral.base-subtle",
        "color": "content.neutral.base.subtle"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.neutral.hover-bold"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "red",
    "css": {
      "container": {
        "bgColor": "background.decorative.red.base-subtle",
        "color": "content.decorative.red"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.red.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "orange",
    "css": {
      "container": {
        "bgColor": "background.decorative.orange.base-subtle",
        "color": "content.decorative.orange"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.orange.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "yellow",
    "css": {
      "container": {
        "bgColor": "background.decorative.yellow.base-subtle",
        "color": "content.decorative.yellow"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.yellow.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "green",
    "css": {
      "container": {
        "bgColor": "background.decorative.green.base-subtle",
        "color": "content.decorative.green"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.green.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "blue",
    "css": {
      "container": {
        "bgColor": "background.decorative.blue.base-subtle",
        "color": "content.decorative.blue"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.blue.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "purple",
    "css": {
      "container": {
        "bgColor": "background.decorative.purple.base-subtle",
        "color": "content.decorative.purple"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.purple.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "subtle",
    "colorTheme": "pink",
    "css": {
      "container": {
        "bgColor": "background.decorative.pink.base-subtle",
        "color": "content.decorative.pink"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.pink.hover-bolder"
        }
      }
    }
  },
  {
    "colorTheme": "reversed",
    "hierarchy": "bold",
    "css": {
      "container": {
        "_withinWhiteLabelTheme": {
          "backgroundColor": "background.neutral-on-brand.base.bold",
          "color": "content.brand.bold"
        }
      },
      "removeTrigger": {
        "color": "inherit",
        "_hover": {
          "bgColor": "background.brand.subtle-hover"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "brand",
    "css": {
      "container": {
        "backgroundColor": "background.brand.bold",
        "_withinWhiteLabelTheme": {
          "color": "content.neutral.bold"
        }
      },
      "removeTrigger": {
        "color": "inherit",
        "_hover": {
          "bgColor": "background.brand.bold-hover",
          "_withinWhiteLabelTheme": {
            "bgColor": "background.neutral-on-brand.base.subtle-hover"
          }
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "neutral",
    "css": {
      "container": {
        "backgroundColor": "background.decorative.neutral.base-bold",
        "_withinWhiteLabelTheme": {
          "backgroundColor": "background.decorative.neutral.base-bolder"
        }
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.neutral.hover-bolder",
          "_withinWhiteLabelTheme": {
            "bgColor": "background.decorative.neutral.hover-subtle",
            "color": "content.neutral.base.bold"
          }
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "red",
    "css": {
      "container": {
        "bgColor": "background.decorative.red.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.red.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "orange",
    "css": {
      "container": {
        "bgColor": "background.decorative.orange.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.orange.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "yellow",
    "css": {
      "container": {
        "bgColor": "background.decorative.yellow.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.yellow.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "green",
    "css": {
      "container": {
        "bgColor": "background.decorative.green.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.green.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "blue",
    "css": {
      "container": {
        "bgColor": "background.decorative.blue.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.blue.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "purple",
    "css": {
      "container": {
        "bgColor": "background.decorative.purple.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.purple.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bold",
    "colorTheme": "pink",
    "css": {
      "container": {
        "bgColor": "background.decorative.pink.base-bold"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.pink.hover-bolder"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "brand",
    "css": {
      "container": {
        "backgroundColor": "background.brand.bold-hover"
      },
      "removeTrigger": {
        "_hover": {
          "color": "content.brand.bold-hover",
          "bgColor": "background.brand.subtle-hover"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "disabled",
    "css": {
      "container": {
        "backgroundColor": "background.neutral.disable.bold",
        "color": "content.neutral.base.disable"
      },
      "removeTrigger": {
        "_hover": {
          "pointerEvents": "none",
          "color": "content.neutral.base.disable",
          "bgColor": "background.neutral.disable.bold"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "red",
    "css": {
      "container": {
        "bgColor": "background.decorative.red.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.red.hover-subtle",
          "color": "content.decorative.red"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "orange",
    "css": {
      "container": {
        "bgColor": "background.decorative.orange.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.orange.hover-subtle",
          "color": "content.decorative.orange"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "yellow",
    "css": {
      "container": {
        "bgColor": "background.decorative.yellow.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.yellow.hover-subtle",
          "color": "content.decorative.yellow"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "green",
    "css": {
      "container": {
        "bgColor": "background.decorative.green.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.green.hover-subtle",
          "color": "content.decorative.green"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "blue",
    "css": {
      "container": {
        "bgColor": "background.decorative.blue.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.blue.hover-subtle",
          "color": "content.decorative.blue"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "purple",
    "css": {
      "container": {
        "bgColor": "background.decorative.purple.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.purple.hover-subtle",
          "color": "content.decorative.purple"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "pink",
    "css": {
      "container": {
        "bgColor": "background.decorative.pink.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.pink.hover-subtle",
          "color": "content.decorative.pink"
        }
      }
    }
  },
  {
    "hierarchy": "bolder",
    "colorTheme": "neutral",
    "css": {
      "container": {
        "bgColor": "background.decorative.neutral.base-bolder"
      },
      "removeTrigger": {
        "_hover": {
          "bgColor": "background.decorative.neutral.hover-subtle",
          "color": "content.neutral.base.bold"
        }
      }
    }
  },
  {
    "iconOnly": true,
    "size": "small",
    "css": {
      "container": {
        "p": "padding.1",
        "minWidth": "s"
      },
      "removeTrigger": {
        "mr": "-padding.0,5"
      }
    }
  },
  {
    "iconOnly": true,
    "size": "large",
    "css": {
      "container": {
        "p": "padding.1,5",
        "minWidth": "m"
      },
      "removeTrigger": {
        "mr": "-padding.0,5"
      }
    }
  }
]

const aromaTagSlotNames = [
  [
    "container",
    "aroma-tag__container"
  ],
  [
    "removeTrigger",
    "aroma-tag__removeTrigger"
  ]
]
const aromaTagSlotFns = /* @__PURE__ */ aromaTagSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaTagDefaultVariants, getSlotCompoundVariant(aromaTagCompoundVariants, slotName))])

const aromaTagFn = memo((props = {}) => {
  return Object.fromEntries(aromaTagSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaTagVariantKeys = [
  "hierarchy",
  "size",
  "iconOnly",
  "colorTheme"
]
const getVariantProps = (variants) => ({ ...aromaTagDefaultVariants, ...compact(variants) })

export const aromaTag = /* @__PURE__ */ Object.assign(aromaTagFn, {
  __recipe__: false,
  __name__: 'aromaTag',
  raw: (props) => props,
  variantKeys: aromaTagVariantKeys,
  variantMap: {
  "hierarchy": [
    "subtlest",
    "subtle",
    "bold",
    "bolder"
  ],
  "size": [
    "small",
    "large"
  ],
  "iconOnly": [
    "true"
  ],
  "colorTheme": [
    "brand",
    "neutral",
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "pink",
    "reversed",
    "disabled"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaTagVariantKeys)
  },
  getVariantProps
})