import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaMessageBubbleDefaultVariants = {
  "type": "primary",
  "rank": "first"
}
const aromaMessageBubbleCompoundVariants = [
  {
    "type": "primary",
    "rank": "first",
    "css": {
      "message": {
        "borderBottomRightRadius": "s"
      }
    }
  },
  {
    "type": "primary",
    "rank": "middle",
    "css": {
      "message": {
        "borderTopRightRadius": "s",
        "borderBottomRightRadius": "s"
      }
    }
  },
  {
    "type": "primary",
    "rank": "last",
    "css": {
      "message": {
        "borderTopRightRadius": "s"
      }
    }
  },
  {
    "type": "secondary",
    "rank": "first",
    "css": {
      "message": {
        "borderBottomLeftRadius": "s"
      },
      "icon": {
        "color": "text.neutral.base.subtler"
      }
    }
  },
  {
    "type": "secondary",
    "rank": "middle",
    "css": {
      "message": {
        "borderTopLeftRadius": "s",
        "borderBottomLeftRadius": "s"
      },
      "icon": {
        "color": "text.neutral.base.subtler"
      }
    }
  },
  {
    "type": "secondary",
    "rank": "last",
    "css": {
      "message": {
        "borderTopLeftRadius": "s"
      },
      "icon": {
        "color": "text.neutral.base.subtler"
      }
    }
  },
  {
    "type": "ai",
    "rank": "first",
    "css": {
      "message": {
        "borderBottomRightRadius": "s"
      }
    }
  },
  {
    "type": "ai",
    "rank": "middle",
    "css": {
      "message": {
        "borderTopRightRadius": "s",
        "borderBottomRightRadius": "s"
      }
    }
  },
  {
    "type": "ai",
    "rank": "last",
    "css": {
      "message": {
        "borderTopRightRadius": "s"
      }
    }
  }
]

const aromaMessageBubbleSlotNames = [
  [
    "message",
    "aroma-message-bubble__message"
  ],
  [
    "icon",
    "aroma-message-bubble__icon"
  ]
]
const aromaMessageBubbleSlotFns = /* @__PURE__ */ aromaMessageBubbleSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaMessageBubbleDefaultVariants, getSlotCompoundVariant(aromaMessageBubbleCompoundVariants, slotName))])

const aromaMessageBubbleFn = memo((props = {}) => {
  return Object.fromEntries(aromaMessageBubbleSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaMessageBubbleVariantKeys = [
  "type",
  "rank"
]
const getVariantProps = (variants) => ({ ...aromaMessageBubbleDefaultVariants, ...compact(variants) })

export const aromaMessageBubble = /* @__PURE__ */ Object.assign(aromaMessageBubbleFn, {
  __recipe__: false,
  __name__: 'aromaMessageBubble',
  raw: (props) => props,
  variantKeys: aromaMessageBubbleVariantKeys,
  variantMap: {
  "type": [
    "primary",
    "secondary",
    "ai"
  ],
  "rank": [
    "first",
    "middle",
    "last",
    "solo"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaMessageBubbleVariantKeys)
  },
  getVariantProps
})