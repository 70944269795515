import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDatepickerInputDefaultVariants = {
  "size": "small",
  "inblock": false
}
const aromaDatepickerInputCompoundVariants = [
  {
    "inblock": true,
    "css": {
      "rangeContainer": {
        "flexDirection": "column"
      }
    }
  }
]

const aromaDatepickerInputSlotNames = [
  [
    "rangeContainer",
    "aroma-input__rangeContainer"
  ],
  [
    "trigger",
    "aroma-input__trigger"
  ]
]
const aromaDatepickerInputSlotFns = /* @__PURE__ */ aromaDatepickerInputSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDatepickerInputDefaultVariants, getSlotCompoundVariant(aromaDatepickerInputCompoundVariants, slotName))])

const aromaDatepickerInputFn = memo((props = {}) => {
  return Object.fromEntries(aromaDatepickerInputSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDatepickerInputVariantKeys = [
  "size",
  "inblock"
]
const getVariantProps = (variants) => ({ ...aromaDatepickerInputDefaultVariants, ...compact(variants) })

export const aromaDatepickerInput = /* @__PURE__ */ Object.assign(aromaDatepickerInputFn, {
  __recipe__: false,
  __name__: 'aromaDatepickerInput',
  raw: (props) => props,
  variantKeys: aromaDatepickerInputVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "inblock": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDatepickerInputVariantKeys)
  },
  getVariantProps
})