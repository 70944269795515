import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaContextualMenuDefaultVariants = {}
const aromaContextualMenuCompoundVariants = []

const aromaContextualMenuSlotNames = [
  [
    "itemGroupLabel",
    "aroma-contextual-menu__itemGroupLabel"
  ],
  [
    "item",
    "aroma-contextual-menu__item"
  ],
  [
    "content",
    "aroma-contextual-menu__content"
  ],
  [
    "action",
    "aroma-contextual-menu__action"
  ],
  [
    "secondaryAction",
    "aroma-contextual-menu__secondaryAction"
  ]
]
const aromaContextualMenuSlotFns = /* @__PURE__ */ aromaContextualMenuSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaContextualMenuDefaultVariants, getSlotCompoundVariant(aromaContextualMenuCompoundVariants, slotName))])

const aromaContextualMenuFn = memo((props = {}) => {
  return Object.fromEntries(aromaContextualMenuSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaContextualMenuVariantKeys = []
const getVariantProps = (variants) => ({ ...aromaContextualMenuDefaultVariants, ...compact(variants) })

export const aromaContextualMenu = /* @__PURE__ */ Object.assign(aromaContextualMenuFn, {
  __recipe__: false,
  __name__: 'aromaContextualMenu',
  raw: (props) => props,
  variantKeys: aromaContextualMenuVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, aromaContextualMenuVariantKeys)
  },
  getVariantProps
})