import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaListItemDefaultVariants = {
  "function": "neutral",
  "size": "small"
}
const aromaListItemCompoundVariants = []

const aromaListItemSlotNames = [
  [
    "root",
    "aroma-list-item__root"
  ],
  [
    "item",
    "aroma-list-item__item"
  ],
  [
    "itemContent",
    "aroma-list-item__itemContent"
  ],
  [
    "itemActions",
    "aroma-list-item__itemActions"
  ]
]
const aromaListItemSlotFns = /* @__PURE__ */ aromaListItemSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaListItemDefaultVariants, getSlotCompoundVariant(aromaListItemCompoundVariants, slotName))])

const aromaListItemFn = memo((props = {}) => {
  return Object.fromEntries(aromaListItemSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaListItemVariantKeys = [
  "function",
  "size"
]
const getVariantProps = (variants) => ({ ...aromaListItemDefaultVariants, ...compact(variants) })

export const aromaListItem = /* @__PURE__ */ Object.assign(aromaListItemFn, {
  __recipe__: false,
  __name__: 'aromaListItem',
  raw: (props) => props,
  variantKeys: aromaListItemVariantKeys,
  variantMap: {
  "function": [
    "neutral",
    "warning",
    "error"
  ],
  "size": [
    "small",
    "medium"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaListItemVariantKeys)
  },
  getVariantProps
})