import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDraggableSignifierDefaultVariants = {}
const aromaDraggableSignifierCompoundVariants = []

const aromaDraggableSignifierSlotNames = [
  [
    "wrapper",
    "aroma-draggable-container-signifier__wrapper"
  ],
  [
    "line",
    "aroma-draggable-container-signifier__line"
  ],
  [
    "ring",
    "aroma-draggable-container-signifier__ring"
  ]
]
const aromaDraggableSignifierSlotFns = /* @__PURE__ */ aromaDraggableSignifierSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDraggableSignifierDefaultVariants, getSlotCompoundVariant(aromaDraggableSignifierCompoundVariants, slotName))])

const aromaDraggableSignifierFn = memo((props = {}) => {
  return Object.fromEntries(aromaDraggableSignifierSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDraggableSignifierVariantKeys = [
  "edge"
]
const getVariantProps = (variants) => ({ ...aromaDraggableSignifierDefaultVariants, ...compact(variants) })

export const aromaDraggableSignifier = /* @__PURE__ */ Object.assign(aromaDraggableSignifierFn, {
  __recipe__: false,
  __name__: 'aromaDraggableSignifier',
  raw: (props) => props,
  variantKeys: aromaDraggableSignifierVariantKeys,
  variantMap: {
  "edge": [
    "top",
    "bottom",
    "left",
    "right"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDraggableSignifierVariantKeys)
  },
  getVariantProps
})