import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaComboboxDefaultVariants = {
  "size": "small"
}
const aromaComboboxCompoundVariants = []

const aromaComboboxSlotNames = [
  [
    "label",
    "aroma-combobox__label"
  ],
  [
    "positioner",
    "aroma-combobox__positioner"
  ],
  [
    "trigger",
    "aroma-combobox__trigger"
  ],
  [
    "indicator",
    "aroma-combobox__indicator"
  ],
  [
    "clearTrigger",
    "aroma-combobox__clearTrigger"
  ],
  [
    "item",
    "aroma-combobox__item"
  ],
  [
    "itemText",
    "aroma-combobox__itemText"
  ],
  [
    "itemIndicator",
    "aroma-combobox__itemIndicator"
  ],
  [
    "itemGroup",
    "aroma-combobox__itemGroup"
  ],
  [
    "itemGroupLabel",
    "aroma-combobox__itemGroupLabel"
  ],
  [
    "list",
    "aroma-combobox__list"
  ],
  [
    "content",
    "aroma-combobox__content"
  ],
  [
    "root",
    "aroma-combobox__root"
  ],
  [
    "control",
    "aroma-combobox__control"
  ],
  [
    "valueText",
    "aroma-combobox__valueText"
  ]
]
const aromaComboboxSlotFns = /* @__PURE__ */ aromaComboboxSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaComboboxDefaultVariants, getSlotCompoundVariant(aromaComboboxCompoundVariants, slotName))])

const aromaComboboxFn = memo((props = {}) => {
  return Object.fromEntries(aromaComboboxSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaComboboxVariantKeys = [
  "size",
  "status"
]
const getVariantProps = (variants) => ({ ...aromaComboboxDefaultVariants, ...compact(variants) })

export const aromaCombobox = /* @__PURE__ */ Object.assign(aromaComboboxFn, {
  __recipe__: false,
  __name__: 'aromaCombobox',
  raw: (props) => props,
  variantKeys: aromaComboboxVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "status": [
    "error",
    "warning"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaComboboxVariantKeys)
  },
  getVariantProps
})