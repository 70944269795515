import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDraggableItemDefaultVariants = {
  "status": "default"
}
const aromaDraggableItemCompoundVariants = [
  {
    "active": true,
    "status": "warning",
    "css": {
      "wrapper": {
        "borderColor": "border.functional.warning"
      },
      "knob": {
        "bgColor": "background.functional.warning.subtle-active",
        "color": "content.functional.warning"
      }
    }
  },
  {
    "active": true,
    "status": "error",
    "css": {
      "wrapper": {
        "borderColor": "border.functional.error"
      },
      "knob": {
        "bgColor": "background.functional.error.subtle-active",
        "color": "content.functional.error"
      }
    }
  }
]

const aromaDraggableItemSlotNames = [
  [
    "wrapper",
    "aroma-draggable-item__wrapper"
  ],
  [
    "knob",
    "aroma-draggable-item__knob"
  ],
  [
    "content",
    "aroma-draggable-item__content"
  ]
]
const aromaDraggableItemSlotFns = /* @__PURE__ */ aromaDraggableItemSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDraggableItemDefaultVariants, getSlotCompoundVariant(aromaDraggableItemCompoundVariants, slotName))])

const aromaDraggableItemFn = memo((props = {}) => {
  return Object.fromEntries(aromaDraggableItemSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDraggableItemVariantKeys = [
  "status",
  "ghost",
  "active"
]
const getVariantProps = (variants) => ({ ...aromaDraggableItemDefaultVariants, ...compact(variants) })

export const aromaDraggableItem = /* @__PURE__ */ Object.assign(aromaDraggableItemFn, {
  __recipe__: false,
  __name__: 'aromaDraggableItem',
  raw: (props) => props,
  variantKeys: aromaDraggableItemVariantKeys,
  variantMap: {
  "status": [
    "warning",
    "error",
    "default"
  ],
  "ghost": [
    "true"
  ],
  "active": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDraggableItemVariantKeys)
  },
  getVariantProps
})