import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaAccordionDefaultVariants = {
  "withBorder": false
}
const aromaAccordionCompoundVariants = []

const aromaAccordionSlotNames = [
  [
    "label",
    "aroma-accordion__label"
  ],
  [
    "positioner",
    "aroma-accordion__positioner"
  ],
  [
    "trigger",
    "aroma-accordion__trigger"
  ],
  [
    "indicator",
    "aroma-accordion__indicator"
  ],
  [
    "clearTrigger",
    "aroma-accordion__clearTrigger"
  ],
  [
    "item",
    "aroma-accordion__item"
  ],
  [
    "itemText",
    "aroma-accordion__itemText"
  ],
  [
    "itemIndicator",
    "aroma-accordion__itemIndicator"
  ],
  [
    "itemGroup",
    "aroma-accordion__itemGroup"
  ],
  [
    "itemGroupLabel",
    "aroma-accordion__itemGroupLabel"
  ],
  [
    "list",
    "aroma-accordion__list"
  ],
  [
    "content",
    "aroma-accordion__content"
  ],
  [
    "root",
    "aroma-accordion__root"
  ],
  [
    "control",
    "aroma-accordion__control"
  ],
  [
    "valueText",
    "aroma-accordion__valueText"
  ]
]
const aromaAccordionSlotFns = /* @__PURE__ */ aromaAccordionSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaAccordionDefaultVariants, getSlotCompoundVariant(aromaAccordionCompoundVariants, slotName))])

const aromaAccordionFn = memo((props = {}) => {
  return Object.fromEntries(aromaAccordionSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaAccordionVariantKeys = [
  "withBorder"
]
const getVariantProps = (variants) => ({ ...aromaAccordionDefaultVariants, ...compact(variants) })

export const aromaAccordion = /* @__PURE__ */ Object.assign(aromaAccordionFn, {
  __recipe__: false,
  __name__: 'aromaAccordion',
  raw: (props) => props,
  variantKeys: aromaAccordionVariantKeys,
  variantMap: {
  "withBorder": [
    "true",
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaAccordionVariantKeys)
  },
  getVariantProps
})