import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaPopoverFn = /* @__PURE__ */ createRecipe('aroma-popover', {
  "size": "middle"
}, [])

const aromaPopoverVariantMap = {
  "size": [
    "small",
    "middle",
    "large"
  ]
}

const aromaPopoverVariantKeys = Object.keys(aromaPopoverVariantMap)

export const aromaPopover = /* @__PURE__ */ Object.assign(memo(aromaPopoverFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaPopover',
  __getCompoundVariantCss__: aromaPopoverFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaPopoverVariantKeys,
  variantMap: aromaPopoverVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaPopoverVariantKeys)
  },
  getVariantProps: aromaPopoverFn.getVariantProps,
})