const tokens = {
  "fonts.sans-serif": {
    "value": "'DM Sans', sans-serif",
    "variable": "var(--fonts-sans-serif)"
  },
  "fonts.serif": {
    "value": "'Tiempos Headline', 'Times New Roman', serif",
    "variable": "var(--fonts-serif)"
  },
  "fonts.monospace": {
    "value": "'DM Mono', monospace",
    "variable": "var(--fonts-monospace)"
  },
  "assets.url-monospace": {
    "value": "url(\"https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,400;0,500;1,400;1,500&display=swap\")",
    "variable": "var(--assets-url-monospace)"
  },
  "assets.url-sans-serif": {
    "value": "url(\"https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,400;0,9..40,500;0,9..40,700;1,9..40,400;1,9..40,500;1,9..40,700&display=swap\")",
    "variable": "var(--assets-url-sans-serif)"
  },
  "fontWeights.regular": {
    "value": "400",
    "variable": "var(--font-weights-regular)"
  },
  "fontWeights.medium": {
    "value": "500",
    "variable": "var(--font-weights-medium)"
  },
  "fontWeights.bold": {
    "value": "700",
    "variable": "var(--font-weights-bold)"
  },
  "fontSizes.63": {
    "value": "10px",
    "variable": "var(--font-sizes-63)"
  },
  "fontSizes.75": {
    "value": "12px",
    "variable": "var(--font-sizes-75)"
  },
  "fontSizes.88": {
    "value": "14px",
    "variable": "var(--font-sizes-88)"
  },
  "fontSizes.100": {
    "value": "16px",
    "variable": "var(--font-sizes-100)"
  },
  "fontSizes.125": {
    "value": "20px",
    "variable": "var(--font-sizes-125)"
  },
  "fontSizes.150": {
    "value": "24px",
    "variable": "var(--font-sizes-150)"
  },
  "fontSizes.200": {
    "value": "32px",
    "variable": "var(--font-sizes-200)"
  },
  "fontSizes.250": {
    "value": "40px",
    "variable": "var(--font-sizes-250)"
  },
  "fontSizes.rem": {
    "value": "16px",
    "variable": "var(--font-sizes-rem)"
  },
  "fontSizes.base": {
    "value": "0.16px",
    "variable": "var(--font-sizes-base)"
  },
  "letterSpacings.4": {
    "value": "0.04em",
    "variable": "var(--letter-spacings-4)"
  },
  "lineHeights.88": {
    "value": "14px",
    "variable": "var(--line-heights-88)"
  },
  "lineHeights.100": {
    "value": "16px",
    "variable": "var(--line-heights-100)"
  },
  "lineHeights.125": {
    "value": "20px",
    "variable": "var(--line-heights-125)"
  },
  "lineHeights.150": {
    "value": "24px",
    "variable": "var(--line-heights-150)"
  },
  "lineHeights.175": {
    "value": "28px",
    "variable": "var(--line-heights-175)"
  },
  "lineHeights.200": {
    "value": "32px",
    "variable": "var(--line-heights-200)"
  },
  "lineHeights.250": {
    "value": "40px",
    "variable": "var(--line-heights-250)"
  },
  "lineHeights.275": {
    "value": "44px",
    "variable": "var(--line-heights-275)"
  },
  "lineHeights.300": {
    "value": "48px",
    "variable": "var(--line-heights-300)"
  },
  "opacity.percentage.0": {
    "value": "0",
    "variable": "var(--opacity-percentage-0)"
  },
  "opacity.percentage.4": {
    "value": 0.04,
    "variable": "var(--opacity-percentage-4)"
  },
  "opacity.percentage.6": {
    "value": 0.06,
    "variable": "var(--opacity-percentage-6)"
  },
  "opacity.percentage.8": {
    "value": 0.08,
    "variable": "var(--opacity-percentage-8)"
  },
  "opacity.percentage.16": {
    "value": 0.16,
    "variable": "var(--opacity-percentage-16)"
  },
  "opacity.percentage.24": {
    "value": 0.24,
    "variable": "var(--opacity-percentage-24)"
  },
  "opacity.percentage.28": {
    "value": 0.28,
    "variable": "var(--opacity-percentage-28)"
  },
  "opacity.percentage.32": {
    "value": 0.32,
    "variable": "var(--opacity-percentage-32)"
  },
  "opacity.percentage.40": {
    "value": 0.4,
    "variable": "var(--opacity-percentage-40)"
  },
  "opacity.percentage.64": {
    "value": 0.64,
    "variable": "var(--opacity-percentage-64)"
  },
  "opacity.percentage.72": {
    "value": 0.72,
    "variable": "var(--opacity-percentage-72)"
  },
  "opacity.percentage.80": {
    "value": 0.8,
    "variable": "var(--opacity-percentage-80)"
  },
  "opacity.percentage.88": {
    "value": 0.88,
    "variable": "var(--opacity-percentage-88)"
  },
  "opacity.percentage.100": {
    "value": 1,
    "variable": "var(--opacity-percentage-100)"
  },
  "opacity.hexadecimal.0": {
    "value": "00",
    "variable": "var(--opacity-hexadecimal-0)"
  },
  "opacity.hexadecimal.4": {
    "value": "0A",
    "variable": "var(--opacity-hexadecimal-4)"
  },
  "opacity.hexadecimal.6": {
    "value": "0F",
    "variable": "var(--opacity-hexadecimal-6)"
  },
  "opacity.hexadecimal.8": {
    "value": "14",
    "variable": "var(--opacity-hexadecimal-8)"
  },
  "opacity.hexadecimal.16": {
    "value": "29",
    "variable": "var(--opacity-hexadecimal-16)"
  },
  "opacity.hexadecimal.24": {
    "value": "3D",
    "variable": "var(--opacity-hexadecimal-24)"
  },
  "opacity.hexadecimal.28": {
    "value": "47",
    "variable": "var(--opacity-hexadecimal-28)"
  },
  "opacity.hexadecimal.32": {
    "value": "52",
    "variable": "var(--opacity-hexadecimal-32)"
  },
  "opacity.hexadecimal.40": {
    "value": "66",
    "variable": "var(--opacity-hexadecimal-40)"
  },
  "opacity.hexadecimal.64": {
    "value": "A3",
    "variable": "var(--opacity-hexadecimal-64)"
  },
  "opacity.hexadecimal.72": {
    "value": "B8",
    "variable": "var(--opacity-hexadecimal-72)"
  },
  "opacity.hexadecimal.80": {
    "value": "CC",
    "variable": "var(--opacity-hexadecimal-80)"
  },
  "opacity.hexadecimal.88": {
    "value": "E0",
    "variable": "var(--opacity-hexadecimal-88)"
  },
  "opacity.hexadecimal.100": {
    "value": "FF",
    "variable": "var(--opacity-hexadecimal-100)"
  },
  "spacing.gap.0": {
    "value": "0px",
    "variable": "var(--spacing-gap-0)"
  },
  "spacing.gap.1": {
    "value": "4px",
    "variable": "var(--spacing-gap-1)"
  },
  "spacing.gap.2": {
    "value": "8px",
    "variable": "var(--spacing-gap-2)"
  },
  "spacing.gap.4": {
    "value": "16px",
    "variable": "var(--spacing-gap-4)"
  },
  "spacing.gap.6": {
    "value": "24px",
    "variable": "var(--spacing-gap-6)"
  },
  "spacing.gap.8": {
    "value": "32px",
    "variable": "var(--spacing-gap-8)"
  },
  "spacing.gap.20": {
    "value": "80px",
    "variable": "var(--spacing-gap-20)"
  },
  "spacing.gap.0,5": {
    "value": "2px",
    "variable": "var(--spacing-gap-0\\,5)"
  },
  "spacing.padding.1": {
    "value": "4px",
    "variable": "var(--spacing-padding-1)"
  },
  "spacing.padding.2": {
    "value": "8px",
    "variable": "var(--spacing-padding-2)"
  },
  "spacing.padding.3": {
    "value": "12px",
    "variable": "var(--spacing-padding-3)"
  },
  "spacing.padding.4": {
    "value": "16px",
    "variable": "var(--spacing-padding-4)"
  },
  "spacing.padding.6": {
    "value": "24px",
    "variable": "var(--spacing-padding-6)"
  },
  "spacing.padding.10": {
    "value": "40px",
    "variable": "var(--spacing-padding-10)"
  },
  "spacing.padding.0,5": {
    "value": "2px",
    "variable": "var(--spacing-padding-0\\,5)"
  },
  "spacing.padding.1,5": {
    "value": "6px",
    "variable": "var(--spacing-padding-1\\,5)"
  },
  "spacing.margin.4": {
    "value": "16px",
    "variable": "var(--spacing-margin-4)"
  },
  "spacing.margin.6": {
    "value": "24px",
    "variable": "var(--spacing-margin-6)"
  },
  "spacing.margin.10": {
    "value": "40px",
    "variable": "var(--spacing-margin-10)"
  },
  "spacing.margin.16": {
    "value": "64px",
    "variable": "var(--spacing-margin-16)"
  },
  "spacing.margin.20": {
    "value": "80px",
    "variable": "var(--spacing-margin-20)"
  },
  "radii.xs": {
    "value": "2px",
    "variable": "var(--radii-xs)"
  },
  "radii.s": {
    "value": "4px",
    "variable": "var(--radii-s)"
  },
  "radii.m": {
    "value": "6px",
    "variable": "var(--radii-m)"
  },
  "radii.l": {
    "value": "8px",
    "variable": "var(--radii-l)"
  },
  "radii.xl": {
    "value": "12px",
    "variable": "var(--radii-xl)"
  },
  "radii.2xl": {
    "value": "16px",
    "variable": "var(--radii-2xl)"
  },
  "radii.pill": {
    "value": "240px",
    "variable": "var(--radii-pill)"
  },
  "borderWidths.m": {
    "value": "1px",
    "variable": "var(--border-widths-m)"
  },
  "borderWidths.l": {
    "value": "2px",
    "variable": "var(--border-widths-l)"
  },
  "borderWidths.xl": {
    "value": "3px",
    "variable": "var(--border-widths-xl)"
  },
  "borderWidths.2xl": {
    "value": "4px",
    "variable": "var(--border-widths-2xl)"
  },
  "zIndex.tooltip": {
    "value": 1070,
    "variable": "var(--z-index-tooltip)"
  },
  "zIndex.floating": {
    "value": 10,
    "variable": "var(--z-index-floating)"
  },
  "sizes.width.3xs": {
    "value": "240px",
    "variable": "var(--sizes-width-3xs)"
  },
  "sizes.width.2xs": {
    "value": "272px",
    "variable": "var(--sizes-width-2xs)"
  },
  "sizes.width.xs": {
    "value": "320px",
    "variable": "var(--sizes-width-xs)"
  },
  "sizes.width.s": {
    "value": "360px",
    "variable": "var(--sizes-width-s)"
  },
  "sizes.width.m": {
    "value": "480px",
    "variable": "var(--sizes-width-m)"
  },
  "sizes.width.l": {
    "value": "560px",
    "variable": "var(--sizes-width-l)"
  },
  "sizes.width.xl": {
    "value": "640px",
    "variable": "var(--sizes-width-xl)"
  },
  "sizes.width.2xl": {
    "value": "720px",
    "variable": "var(--sizes-width-2xl)"
  },
  "sizes.width.3xl": {
    "value": "880px",
    "variable": "var(--sizes-width-3xl)"
  },
  "sizes.layout.xs": {
    "value": "480px",
    "variable": "var(--sizes-layout-xs)"
  },
  "sizes.layout.s": {
    "value": "768px",
    "variable": "var(--sizes-layout-s)"
  },
  "sizes.layout.m": {
    "value": "1024px",
    "variable": "var(--sizes-layout-m)"
  },
  "sizes.layout.l": {
    "value": "1440px",
    "variable": "var(--sizes-layout-l)"
  },
  "sizes.4xs": {
    "value": "8px",
    "variable": "var(--sizes-4xs)"
  },
  "sizes.3xs": {
    "value": "12px",
    "variable": "var(--sizes-3xs)"
  },
  "sizes.2xs": {
    "value": "16px",
    "variable": "var(--sizes-2xs)"
  },
  "sizes.xs": {
    "value": "20px",
    "variable": "var(--sizes-xs)"
  },
  "sizes.s": {
    "value": "24px",
    "variable": "var(--sizes-s)"
  },
  "sizes.m": {
    "value": "32px",
    "variable": "var(--sizes-m)"
  },
  "sizes.l": {
    "value": "36px",
    "variable": "var(--sizes-l)"
  },
  "sizes.xl": {
    "value": "40px",
    "variable": "var(--sizes-xl)"
  },
  "sizes.2xl": {
    "value": "48px",
    "variable": "var(--sizes-2xl)"
  },
  "sizes.3xl": {
    "value": "80px",
    "variable": "var(--sizes-3xl)"
  },
  "sizes.narrow-width": {
    "value": "600px",
    "variable": "var(--sizes-narrow-width)"
  },
  "sizes.wide-width": {
    "value": "1024px",
    "variable": "var(--sizes-wide-width)"
  },
  "sizes.scrollable-accordion-height": {
    "value": "360px",
    "variable": "var(--sizes-scrollable-accordion-height)"
  },
  "sizes.breakpoint-xs": {
    "value": "480px",
    "variable": "var(--sizes-breakpoint-xs)"
  },
  "sizes.breakpoint-sm": {
    "value": "480px",
    "variable": "var(--sizes-breakpoint-sm)"
  },
  "sizes.breakpoint-md": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-md)"
  },
  "sizes.breakpoint-lg": {
    "value": "1024px",
    "variable": "var(--sizes-breakpoint-lg)"
  },
  "colors.oat": {
    "value": "#F2E8E3",
    "variable": "var(--colors-oat)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "colors.blue-cheese": {
    "value": "#CEE1F9",
    "variable": "var(--colors-blue-cheese)"
  },
  "colors.digital-matcha": {
    "value": "#D2FC90",
    "variable": "var(--colors-digital-matcha)"
  },
  "colors.cream": {
    "value": "#EDCBAB",
    "variable": "var(--colors-cream)"
  },
  "colors.coffee": {
    "value": "#5A4027",
    "variable": "var(--colors-coffee)"
  },
  "colors.kale": {
    "value": "#555F54",
    "variable": "var(--colors-kale)"
  },
  "colors.blue-tape.50": {
    "value": "#EBF0FC",
    "variable": "var(--colors-blue-tape-50)"
  },
  "colors.blue-tape.100": {
    "value": "#D7E2FB",
    "variable": "var(--colors-blue-tape-100)"
  },
  "colors.blue-tape.200": {
    "value": "#C2D1F6",
    "variable": "var(--colors-blue-tape-200)"
  },
  "colors.blue-tape.300": {
    "value": "#99B3F0",
    "variable": "var(--colors-blue-tape-300)"
  },
  "colors.blue-tape.400": {
    "value": "#7095EA",
    "variable": "var(--colors-blue-tape-400)"
  },
  "colors.blue-tape.500": {
    "value": "#4776E4",
    "variable": "var(--colors-blue-tape-500)"
  },
  "colors.blue-tape.600": {
    "value": "#1F58E0",
    "variable": "var(--colors-blue-tape-600)"
  },
  "colors.blue-tape.700": {
    "value": "#0041D9",
    "variable": "var(--colors-blue-tape-700)"
  },
  "colors.blue-tape.800": {
    "value": "#002782",
    "variable": "var(--colors-blue-tape-800)"
  },
  "colors.blue-tape.900": {
    "value": "#001A57",
    "variable": "var(--colors-blue-tape-900)"
  },
  "colors.olive.50": {
    "value": "#F0F0E8",
    "variable": "var(--colors-olive-50)"
  },
  "colors.olive.100": {
    "value": "#E6E6D8",
    "variable": "var(--colors-olive-100)"
  },
  "colors.olive.200": {
    "value": "#D5D5BF",
    "variable": "var(--colors-olive-200)"
  },
  "colors.olive.300": {
    "value": "#C3C3A3",
    "variable": "var(--colors-olive-300)"
  },
  "colors.olive.400": {
    "value": "#ADAD81",
    "variable": "var(--colors-olive-400)"
  },
  "colors.olive.500": {
    "value": "#97975E",
    "variable": "var(--colors-olive-500)"
  },
  "colors.olive.600": {
    "value": "#80803C",
    "variable": "var(--colors-olive-600)"
  },
  "colors.olive.700": {
    "value": "#6A6A19",
    "variable": "var(--colors-olive-700)"
  },
  "colors.olive.800": {
    "value": "#4A4A11",
    "variable": "var(--colors-olive-800)"
  },
  "colors.olive.900": {
    "value": "#33330C",
    "variable": "var(--colors-olive-900)"
  },
  "colors.charcoal.50": {
    "value": "#F6F6F6",
    "variable": "var(--colors-charcoal-50)"
  },
  "colors.charcoal.100": {
    "value": "#EDEEEE",
    "variable": "var(--colors-charcoal-100)"
  },
  "colors.charcoal.200": {
    "value": "#E5E5E5",
    "variable": "var(--colors-charcoal-200)"
  },
  "colors.charcoal.300": {
    "value": "#D3D3D3",
    "variable": "var(--colors-charcoal-300)"
  },
  "colors.charcoal.400": {
    "value": "#B5B5B5",
    "variable": "var(--colors-charcoal-400)"
  },
  "colors.charcoal.500": {
    "value": "#6E6F6F",
    "variable": "var(--colors-charcoal-500)"
  },
  "colors.charcoal.600": {
    "value": "#505151",
    "variable": "var(--colors-charcoal-600)"
  },
  "colors.charcoal.700": {
    "value": "#242525",
    "variable": "var(--colors-charcoal-700)"
  },
  "colors.charcoal.800": {
    "value": "#1A1B1B",
    "variable": "var(--colors-charcoal-800)"
  },
  "colors.charcoal.900": {
    "value": "#0D0D0D",
    "variable": "var(--colors-charcoal-900)"
  },
  "colors.ink.50": {
    "value": "#F5F5F7",
    "variable": "var(--colors-ink-50)"
  },
  "colors.ink.100": {
    "value": "#F0F0F2",
    "variable": "var(--colors-ink-100)"
  },
  "colors.ink.200": {
    "value": "#E5E7EA",
    "variable": "var(--colors-ink-200)"
  },
  "colors.ink.300": {
    "value": "#D1D3D9",
    "variable": "var(--colors-ink-300)"
  },
  "colors.ink.400": {
    "value": "#B3B6C0",
    "variable": "var(--colors-ink-400)"
  },
  "colors.ink.500": {
    "value": "#666E80",
    "variable": "var(--colors-ink-500)"
  },
  "colors.ink.600": {
    "value": "#475167",
    "variable": "var(--colors-ink-600)"
  },
  "colors.ink.700": {
    "value": "#333D56",
    "variable": "var(--colors-ink-700)"
  },
  "colors.ink.800": {
    "value": "#14203D",
    "variable": "var(--colors-ink-800)"
  },
  "colors.ink.900": {
    "value": "#000D2C",
    "variable": "var(--colors-ink-900)"
  },
  "colors.green.50": {
    "value": "#EEF8EF",
    "variable": "var(--colors-green-50)"
  },
  "colors.green.100": {
    "value": "#DCF4E0",
    "variable": "var(--colors-green-100)"
  },
  "colors.green.200": {
    "value": "#CBEAD0",
    "variable": "var(--colors-green-200)"
  },
  "colors.green.300": {
    "value": "#A8DCB1",
    "variable": "var(--colors-green-300)"
  },
  "colors.green.400": {
    "value": "#85CE92",
    "variable": "var(--colors-green-400)"
  },
  "colors.green.500": {
    "value": "#26A83C",
    "variable": "var(--colors-green-500)"
  },
  "colors.green.600": {
    "value": "#1E8630",
    "variable": "var(--colors-green-600)"
  },
  "colors.green.700": {
    "value": "#176524",
    "variable": "var(--colors-green-700)"
  },
  "colors.green.800": {
    "value": "#0F4318",
    "variable": "var(--colors-green-800)"
  },
  "colors.green.900": {
    "value": "#0B3212",
    "variable": "var(--colors-green-900)"
  },
  "colors.blue.50": {
    "value": "#EBF0FC",
    "variable": "var(--colors-blue-50)"
  },
  "colors.blue.100": {
    "value": "#D7E2FB",
    "variable": "var(--colors-blue-100)"
  },
  "colors.blue.200": {
    "value": "#C2D1F6",
    "variable": "var(--colors-blue-200)"
  },
  "colors.blue.300": {
    "value": "#99B3F0",
    "variable": "var(--colors-blue-300)"
  },
  "colors.blue.400": {
    "value": "#7095EA",
    "variable": "var(--colors-blue-400)"
  },
  "colors.blue.500": {
    "value": "#4776E4",
    "variable": "var(--colors-blue-500)"
  },
  "colors.blue.600": {
    "value": "#1F58E0",
    "variable": "var(--colors-blue-600)"
  },
  "colors.blue.700": {
    "value": "#0041D9",
    "variable": "var(--colors-blue-700)"
  },
  "colors.blue.800": {
    "value": "#002782",
    "variable": "var(--colors-blue-800)"
  },
  "colors.blue.900": {
    "value": "#001A57",
    "variable": "var(--colors-blue-900)"
  },
  "colors.orange.50": {
    "value": "#FEEFDD",
    "variable": "var(--colors-orange-50)"
  },
  "colors.orange.100": {
    "value": "#FEE4C3",
    "variable": "var(--colors-orange-100)"
  },
  "colors.orange.200": {
    "value": "#FDD9AA",
    "variable": "var(--colors-orange-200)"
  },
  "colors.orange.300": {
    "value": "#FBBE74",
    "variable": "var(--colors-orange-300)"
  },
  "colors.orange.400": {
    "value": "#F8A33F",
    "variable": "var(--colors-orange-400)"
  },
  "colors.orange.500": {
    "value": "#FD9902",
    "variable": "var(--colors-orange-500)"
  },
  "colors.orange.600": {
    "value": "#E76D0F",
    "variable": "var(--colors-orange-600)"
  },
  "colors.orange.700": {
    "value": "#BF520F",
    "variable": "var(--colors-orange-700)"
  },
  "colors.orange.800": {
    "value": "#8C390E",
    "variable": "var(--colors-orange-800)"
  },
  "colors.orange.900": {
    "value": "#682909",
    "variable": "var(--colors-orange-900)"
  },
  "colors.red.50": {
    "value": "#FEECED",
    "variable": "var(--colors-red-50)"
  },
  "colors.red.100": {
    "value": "#FCDDDF",
    "variable": "var(--colors-red-100)"
  },
  "colors.red.200": {
    "value": "#FECACC",
    "variable": "var(--colors-red-200)"
  },
  "colors.red.300": {
    "value": "#FCA5A8",
    "variable": "var(--colors-red-300)"
  },
  "colors.red.400": {
    "value": "#F77277",
    "variable": "var(--colors-red-400)"
  },
  "colors.red.500": {
    "value": "#ED383F",
    "variable": "var(--colors-red-500)"
  },
  "colors.red.600": {
    "value": "#CC1F25",
    "variable": "var(--colors-red-600)"
  },
  "colors.red.700": {
    "value": "#B2171D",
    "variable": "var(--colors-red-700)"
  },
  "colors.red.800": {
    "value": "#961A1F",
    "variable": "var(--colors-red-800)"
  },
  "colors.red.900": {
    "value": "#731216",
    "variable": "var(--colors-red-900)"
  },
  "colors.yellow.50": {
    "value": "#FFF0CD",
    "variable": "var(--colors-yellow-50)"
  },
  "colors.yellow.100": {
    "value": "#FBE5B2",
    "variable": "var(--colors-yellow-100)"
  },
  "colors.yellow.200": {
    "value": "#F4D078",
    "variable": "var(--colors-yellow-200)"
  },
  "colors.yellow.300": {
    "value": "#EFC050",
    "variable": "var(--colors-yellow-300)"
  },
  "colors.yellow.400": {
    "value": "#E9B129",
    "variable": "var(--colors-yellow-400)"
  },
  "colors.yellow.500": {
    "value": "#E4A201",
    "variable": "var(--colors-yellow-500)"
  },
  "colors.yellow.600": {
    "value": "#BF8801",
    "variable": "var(--colors-yellow-600)"
  },
  "colors.yellow.700": {
    "value": "#976B01",
    "variable": "var(--colors-yellow-700)"
  },
  "colors.yellow.800": {
    "value": "#5D4200",
    "variable": "var(--colors-yellow-800)"
  },
  "colors.yellow.900": {
    "value": "#362700",
    "variable": "var(--colors-yellow-900)"
  },
  "colors.purple.50": {
    "value": "#F1EDFF",
    "variable": "var(--colors-purple-50)"
  },
  "colors.purple.100": {
    "value": "#E6DEFF",
    "variable": "var(--colors-purple-100)"
  },
  "colors.purple.200": {
    "value": "#E1D7FD",
    "variable": "var(--colors-purple-200)"
  },
  "colors.purple.300": {
    "value": "#B399FD",
    "variable": "var(--colors-purple-300)"
  },
  "colors.purple.400": {
    "value": "#8B64FB",
    "variable": "var(--colors-purple-400)"
  },
  "colors.purple.500": {
    "value": "#6A38FB",
    "variable": "var(--colors-purple-500)"
  },
  "colors.purple.600": {
    "value": "#5218FA",
    "variable": "var(--colors-purple-600)"
  },
  "colors.purple.700": {
    "value": "#3D0FC1",
    "variable": "var(--colors-purple-700)"
  },
  "colors.purple.800": {
    "value": "#330BA5",
    "variable": "var(--colors-purple-800)"
  },
  "colors.purple.900": {
    "value": "#280688",
    "variable": "var(--colors-purple-900)"
  },
  "colors.pink.50": {
    "value": "#FFEEF6",
    "variable": "var(--colors-pink-50)"
  },
  "colors.pink.100": {
    "value": "#FADFEC",
    "variable": "var(--colors-pink-100)"
  },
  "colors.pink.200": {
    "value": "#F5C6DD",
    "variable": "var(--colors-pink-200)"
  },
  "colors.pink.300": {
    "value": "#EC95BE",
    "variable": "var(--colors-pink-300)"
  },
  "colors.pink.400": {
    "value": "#E263A0",
    "variable": "var(--colors-pink-400)"
  },
  "colors.pink.500": {
    "value": "#D93281",
    "variable": "var(--colors-pink-500)"
  },
  "colors.pink.600": {
    "value": "#CF0063",
    "variable": "var(--colors-pink-600)"
  },
  "colors.pink.700": {
    "value": "#A6004F",
    "variable": "var(--colors-pink-700)"
  },
  "colors.pink.800": {
    "value": "#7C003B",
    "variable": "var(--colors-pink-800)"
  },
  "colors.pink.900": {
    "value": "#530028",
    "variable": "var(--colors-pink-900)"
  },
  "colors.whiteLevel.7": {
    "value": "rgba(255,255,255,0.07)",
    "variable": "var(--colors-white-level-7)"
  },
  "colors.whiteLevel.10": {
    "value": "rgba(255,255,255,0.1)",
    "variable": "var(--colors-white-level-10)"
  },
  "colors.whiteLevel.15": {
    "value": "rgba(255,255,255,0.15)",
    "variable": "var(--colors-white-level-15)"
  },
  "colors.whiteLevel.20": {
    "value": "rgba(255,255,255,0.2)",
    "variable": "var(--colors-white-level-20)"
  },
  "colors.whiteLevel.30": {
    "value": "rgba(255,255,255,0.3)",
    "variable": "var(--colors-white-level-30)"
  },
  "colors.whiteLevel.60": {
    "value": "rgba(255,255,255,0.6)",
    "variable": "var(--colors-white-level-60)"
  },
  "colors.whiteLevel.70": {
    "value": "rgba(255,255,255,0.7)",
    "variable": "var(--colors-white-level-70)"
  },
  "colors.whiteLevel.100": {
    "value": "rgba(255,255,255,1)",
    "variable": "var(--colors-white-level-100)"
  },
  "colors.blackLevel.5": {
    "value": "rgba(0,0,0,0.05)",
    "variable": "var(--colors-black-level-5)"
  },
  "colors.blackLevel.7": {
    "value": "rgba(0,0,0,0.07)",
    "variable": "var(--colors-black-level-7)"
  },
  "colors.blackLevel.10": {
    "value": "rgba(0,0,0,0.1)",
    "variable": "var(--colors-black-level-10)"
  },
  "colors.blackLevel.15": {
    "value": "rgba(0,0,0,0.15)",
    "variable": "var(--colors-black-level-15)"
  },
  "colors.blackLevel.20": {
    "value": "rgba(0,0,0,0.2)",
    "variable": "var(--colors-black-level-20)"
  },
  "colors.blackLevel.30": {
    "value": "rgba(0,0,0,0.3)",
    "variable": "var(--colors-black-level-30)"
  },
  "colors.blackLevel.60": {
    "value": "rgba(0,0,0,0.6)",
    "variable": "var(--colors-black-level-60)"
  },
  "colors.blackLevel.70": {
    "value": "rgba(0,0,0,0.7)",
    "variable": "var(--colors-black-level-70)"
  },
  "colors.blackLevel.100": {
    "value": "rgba(0,0,0,1)",
    "variable": "var(--colors-black-level-100)"
  },
  "animations.fadeInUp": {
    "value": "fadeInUp .3s",
    "variable": "var(--animations-fade-in-up)"
  },
  "borders.m": {
    "value": "var(--border-widths-m) solid",
    "variable": "var(--borders-m)"
  },
  "breakpoints.xs": {
    "value": "480px",
    "variable": "var(--breakpoints-xs)"
  },
  "breakpoints.sm": {
    "value": "480px",
    "variable": "var(--breakpoints-sm)"
  },
  "breakpoints.md": {
    "value": "768px",
    "variable": "var(--breakpoints-md)"
  },
  "breakpoints.lg": {
    "value": "1024px",
    "variable": "var(--breakpoints-lg)"
  },
  "shadows.effect.focus-ring.brand": {
    "value": "var(--shadows-effect-focus-ring-brand)",
    "variable": "var(--shadows-effect-focus-ring-brand)"
  },
  "shadows.effect.focus-ring.ai": {
    "value": "0 0 0 4px var(--colors-focus-ring-ai)",
    "variable": "var(--shadows-effect-focus-ring-ai)"
  },
  "shadows.effect.focus-ring.error": {
    "value": "0 0 0 4px var(--colors-red-200)",
    "variable": "var(--shadows-effect-focus-ring-error)"
  },
  "shadows.effect.focus-ring.warning": {
    "value": "0 0 0 4px var(--colors-orange-200)",
    "variable": "var(--shadows-effect-focus-ring-warning)"
  },
  "shadows.effect.focus-ring.success": {
    "value": "0 0 0 4px var(--colors-green-200)",
    "variable": "var(--shadows-effect-focus-ring-success)"
  },
  "shadows.effect.focus-ring.info": {
    "value": "0 0 0 4px var(--colors-blue-200)",
    "variable": "var(--shadows-effect-focus-ring-info)"
  },
  "shadows.effect.shadow.subtle-bottom": {
    "value": "0 1px 16px 0 #1A1B1B0A, 0 2px 2px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-subtle-bottom)"
  },
  "shadows.effect.shadow.subtle-top": {
    "value": "0 -1px 16px 0 #1A1B1B0A, 0 -2px 2px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-subtle-top)"
  },
  "shadows.effect.shadow.bold-bottom": {
    "value": "0 2px 24px 0 #1A1B1B0F, 0 2px 4px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-bold-bottom)"
  },
  "shadows.effect.shadow.bold-top": {
    "value": "0 -2px 24px 0 #1A1B1B0F, 0 -2px 4px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-bold-top)"
  },
  "shadows.effect.shadow.bolder-bottom": {
    "value": "0 8px 48px 0 #1A1B1B14, 0 2px 6px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-bolder-bottom)"
  },
  "shadows.effect.shadow.ai": {
    "value": "0 8px 48px 0 #330BA514, 0 2px 6px 0 #EC95BE14",
    "variable": "var(--shadows-effect-shadow-ai)"
  },
  "shadows.effect.shadow.bolder-top": {
    "value": "0 -8px 48px 0 #1A1B1B14, 0 -2px 6px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-bolder-top)"
  },
  "shadows.effect.shadow.bolder-right": {
    "value": "8px 0 48px 0 #1A1B1B14, 2px 0 6px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-bolder-right)"
  },
  "shadows.effect.shadow.bolder-left": {
    "value": "-8px 0 48px 0 #1A1B1B14, -2px 0 6px 0 #EDEEEE14",
    "variable": "var(--shadows-effect-shadow-bolder-left)"
  },
  "colors.background.brand.subtle": {
    "value": "var(--colors-background-brand-subtle)",
    "variable": "var(--colors-background-brand-subtle)"
  },
  "colors.background.brand.subtle-hover": {
    "value": "var(--colors-background-brand-subtle-hover)",
    "variable": "var(--colors-background-brand-subtle-hover)"
  },
  "colors.background.brand.subtle-active": {
    "value": "var(--colors-olive-400)",
    "variable": "var(--colors-background-brand-subtle-active)"
  },
  "colors.background.brand.bold": {
    "value": "var(--colors-background-brand-bold)",
    "variable": "var(--colors-background-brand-bold)"
  },
  "colors.background.brand.bold-hover": {
    "value": "var(--colors-background-brand-bold-hover)",
    "variable": "var(--colors-background-brand-bold-hover)"
  },
  "colors.background.brand.bold-active": {
    "value": "var(--colors-olive-900)",
    "variable": "var(--colors-background-brand-bold-active)"
  },
  "colors.background.brand.bolder": {
    "value": "var(--colors-olive-900)",
    "variable": "var(--colors-background-brand-bolder)"
  },
  "colors.background.brand.bolder-hover": {
    "value": "var(--colors-olive-800)",
    "variable": "var(--colors-background-brand-bolder-hover)"
  },
  "colors.background.brand.bolder-active": {
    "value": "var(--colors-olive-700)",
    "variable": "var(--colors-background-brand-bolder-active)"
  },
  "colors.background.brand.complementary": {
    "value": "var(--colors-oat)",
    "variable": "var(--colors-background-brand-complementary)"
  },
  "colors.background.brand.complementary-reversed": {
    "value": "var(--colors-digital-matcha)",
    "variable": "var(--colors-background-brand-complementary-reversed)"
  },
  "colors.background.ai.subtler": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-background-ai-subtler)"
  },
  "colors.background.ai.subtler-hover": {
    "value": "linear-gradient(45deg, var(--colors-purple-50) 0%, var(--colors-pink-50) 100%)",
    "variable": "var(--colors-background-ai-subtler-hover)"
  },
  "colors.background.ai.subtler-active": {
    "value": "linear-gradient(45deg, var(--colors-purple-100) 0%, var(--colors-pink-100) 100%)",
    "variable": "var(--colors-background-ai-subtler-active)"
  },
  "colors.background.ai.subtle": {
    "value": "linear-gradient(45deg, var(--colors-purple-100) 0%, var(--colors-pink-100) 100%)",
    "variable": "var(--colors-background-ai-subtle)"
  },
  "colors.background.ai.subtle-hover": {
    "value": "linear-gradient(45deg, var(--colors-purple-200) 0%, var(--colors-pink-200) 100%)",
    "variable": "var(--colors-background-ai-subtle-hover)"
  },
  "colors.background.ai.subtle-active": {
    "value": "linear-gradient(45deg, var(--colors-purple-50) 0%, var(--colors-pink-50) 100%)",
    "variable": "var(--colors-background-ai-subtle-active)"
  },
  "colors.background.ai.bold": {
    "value": "linear-gradient(45deg, var(--colors-purple-500) 0%, var(--colors-pink-500) 100%)",
    "variable": "var(--colors-background-ai-bold)"
  },
  "colors.background.ai.bold-hover": {
    "value": "linear-gradient(45deg, var(--colors-purple-600) 0%, var(--colors-pink-600) 100%)",
    "variable": "var(--colors-background-ai-bold-hover)"
  },
  "colors.background.ai.bold-active": {
    "value": "linear-gradient(45deg, var(--colors-purple-700) 0%, var(--colors-pink-700) 100%)",
    "variable": "var(--colors-background-ai-bold-active)"
  },
  "colors.background.neutral.base.subtlest": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-background-neutral-base-subtlest)"
  },
  "colors.background.neutral.base.subtlest-hover": {
    "value": "var(--colors-charcoal-50)",
    "variable": "var(--colors-background-neutral-base-subtlest-hover)"
  },
  "colors.background.neutral.base.subtlest-active": {
    "value": "var(--colors-charcoal-100)",
    "variable": "var(--colors-background-neutral-base-subtlest-active)"
  },
  "colors.background.neutral.base.subtler": {
    "value": "var(--colors-charcoal-50)",
    "variable": "var(--colors-background-neutral-base-subtler)"
  },
  "colors.background.neutral.base.subtler-hover": {
    "value": "var(--colors-charcoal-100)",
    "variable": "var(--colors-background-neutral-base-subtler-hover)"
  },
  "colors.background.neutral.base.subtler-active": {
    "value": "var(--colors-charcoal-200)",
    "variable": "var(--colors-background-neutral-base-subtler-active)"
  },
  "colors.background.neutral.base.subtle": {
    "value": "var(--colors-charcoal-300)",
    "variable": "var(--colors-background-neutral-base-subtle)"
  },
  "colors.background.neutral.base.subtle-hover": {
    "value": "var(--colors-charcoal-400)",
    "variable": "var(--colors-background-neutral-base-subtle-hover)"
  },
  "colors.background.neutral.base.subtle-active": {
    "value": "var(--colors-charcoal-200)",
    "variable": "var(--colors-background-neutral-base-subtle-active)"
  },
  "colors.background.neutral.base.bold": {
    "value": "var(--colors-charcoal-800)",
    "variable": "var(--colors-background-neutral-base-bold)"
  },
  "colors.background.neutral.base.bold-hover": {
    "value": "var(--colors-charcoal-900)",
    "variable": "var(--colors-background-neutral-base-bold-hover)"
  },
  "colors.background.neutral.base.bold-active": {
    "value": "var(--colors-charcoal-700)",
    "variable": "var(--colors-background-neutral-base-bold-active)"
  },
  "colors.background.neutral.disable.subtlest": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-background-neutral-disable-subtlest)"
  },
  "colors.background.neutral.disable.subtle": {
    "value": "var(--colors-charcoal-50)",
    "variable": "var(--colors-background-neutral-disable-subtle)"
  },
  "colors.background.neutral.disable.bold": {
    "value": "var(--colors-charcoal-200)",
    "variable": "var(--colors-background-neutral-disable-bold)"
  },
  "colors.background.neutral.disable.bolder": {
    "value": "var(--colors-charcoal-300)",
    "variable": "var(--colors-background-neutral-disable-bolder)"
  },
  "colors.background.neutral.disable.reverse": {
    "value": "#FFFFFF29",
    "variable": "var(--colors-background-neutral-disable-reverse)"
  },
  "colors.background.neutral.overlay.subtler": {
    "value": "#FFFFFF29",
    "variable": "var(--colors-background-neutral-overlay-subtler)"
  },
  "colors.background.neutral.overlay.subtle": {
    "value": "#FFFFFFCC",
    "variable": "var(--colors-background-neutral-overlay-subtle)"
  },
  "colors.background.neutral.overlay.bold": {
    "value": "#0D0D0DCC",
    "variable": "var(--colors-background-neutral-overlay-bold)"
  },
  "colors.background.neutral.reverse.subtlest": {
    "value": "#FFFFFF0A",
    "variable": "var(--colors-background-neutral-reverse-subtlest)"
  },
  "colors.background.neutral.reverse.subtlest-hover": {
    "value": "#FFFFFF14",
    "variable": "var(--colors-background-neutral-reverse-subtlest-hover)"
  },
  "colors.background.neutral.reverse.subtlest-active": {
    "value": "#FFFFFF29",
    "variable": "var(--colors-background-neutral-reverse-subtlest-active)"
  },
  "colors.background.neutral.reverse.subtler": {
    "value": "#FFFFFF14",
    "variable": "var(--colors-background-neutral-reverse-subtler)"
  },
  "colors.background.neutral.reverse.subtler-hover": {
    "value": "#FFFFFF29",
    "variable": "var(--colors-background-neutral-reverse-subtler-hover)"
  },
  "colors.background.neutral.reverse.subtler-active": {
    "value": "#FFFFFF3D",
    "variable": "var(--colors-background-neutral-reverse-subtler-active)"
  },
  "colors.background.neutral.reverse.subtle": {
    "value": "#FFFFFF3D",
    "variable": "var(--colors-background-neutral-reverse-subtle)"
  },
  "colors.background.neutral.reverse.subtle-hover": {
    "value": "#FFFFFF52",
    "variable": "var(--colors-background-neutral-reverse-subtle-hover)"
  },
  "colors.background.neutral.reverse.subtle-active": {
    "value": "#FFFFFF66",
    "variable": "var(--colors-background-neutral-reverse-subtle-active)"
  },
  "colors.background.neutral.reverse.bold": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-background-neutral-reverse-bold)"
  },
  "colors.background.neutral.reverse.bold-hover": {
    "value": "#FFFFFFCC",
    "variable": "var(--colors-background-neutral-reverse-bold-hover)"
  },
  "colors.background.neutral.reverse.bold-active": {
    "value": "#FFFFFFE0",
    "variable": "var(--colors-background-neutral-reverse-bold-active)"
  },
  "colors.background.functional.error.subtle": {
    "value": "var(--colors-red-50)",
    "variable": "var(--colors-background-functional-error-subtle)"
  },
  "colors.background.functional.error.subtle-hover": {
    "value": "var(--colors-red-100)",
    "variable": "var(--colors-background-functional-error-subtle-hover)"
  },
  "colors.background.functional.error.subtle-active": {
    "value": "var(--colors-red-200)",
    "variable": "var(--colors-background-functional-error-subtle-active)"
  },
  "colors.background.functional.error.bold": {
    "value": "var(--colors-red-600)",
    "variable": "var(--colors-background-functional-error-bold)"
  },
  "colors.background.functional.error.bold-hover": {
    "value": "var(--colors-red-700)",
    "variable": "var(--colors-background-functional-error-bold-hover)"
  },
  "colors.background.functional.error.bold-active": {
    "value": "var(--colors-red-800)",
    "variable": "var(--colors-background-functional-error-bold-active)"
  },
  "colors.background.functional.warning.subtle": {
    "value": "var(--colors-orange-50)",
    "variable": "var(--colors-background-functional-warning-subtle)"
  },
  "colors.background.functional.warning.subtle-hover": {
    "value": "var(--colors-orange-100)",
    "variable": "var(--colors-background-functional-warning-subtle-hover)"
  },
  "colors.background.functional.warning.subtle-active": {
    "value": "var(--colors-orange-200)",
    "variable": "var(--colors-background-functional-warning-subtle-active)"
  },
  "colors.background.functional.warning.bold": {
    "value": "var(--colors-orange-600)",
    "variable": "var(--colors-background-functional-warning-bold)"
  },
  "colors.background.functional.warning.bold-hover": {
    "value": "var(--colors-orange-700)",
    "variable": "var(--colors-background-functional-warning-bold-hover)"
  },
  "colors.background.functional.warning.bold-active": {
    "value": "var(--colors-orange-800)",
    "variable": "var(--colors-background-functional-warning-bold-active)"
  },
  "colors.background.functional.success.subtle": {
    "value": "var(--colors-green-50)",
    "variable": "var(--colors-background-functional-success-subtle)"
  },
  "colors.background.functional.success.subtle-hover": {
    "value": "var(--colors-green-100)",
    "variable": "var(--colors-background-functional-success-subtle-hover)"
  },
  "colors.background.functional.success.subtle-active": {
    "value": "var(--colors-green-200)",
    "variable": "var(--colors-background-functional-success-subtle-active)"
  },
  "colors.background.functional.success.bold": {
    "value": "var(--colors-green-600)",
    "variable": "var(--colors-background-functional-success-bold)"
  },
  "colors.background.functional.success.bold-hover": {
    "value": "var(--colors-green-700)",
    "variable": "var(--colors-background-functional-success-bold-hover)"
  },
  "colors.background.functional.success.bold-active": {
    "value": "var(--colors-green-800)",
    "variable": "var(--colors-background-functional-success-bold-active)"
  },
  "colors.background.functional.info.subtle": {
    "value": "var(--colors-blue-50)",
    "variable": "var(--colors-background-functional-info-subtle)"
  },
  "colors.background.functional.info.subtle-hover": {
    "value": "var(--colors-blue-100)",
    "variable": "var(--colors-background-functional-info-subtle-hover)"
  },
  "colors.background.functional.info.subtle-active": {
    "value": "var(--colors-blue-200)",
    "variable": "var(--colors-background-functional-info-subtle-active)"
  },
  "colors.background.functional.info.bold": {
    "value": "var(--colors-blue-600)",
    "variable": "var(--colors-background-functional-info-bold)"
  },
  "colors.background.functional.info.bold-hover": {
    "value": "var(--colors-blue-700)",
    "variable": "var(--colors-background-functional-info-bold-hover)"
  },
  "colors.background.functional.info.bold-active": {
    "value": "var(--colors-blue-800)",
    "variable": "var(--colors-background-functional-info-bold-active)"
  },
  "colors.background.decorative.neutral.base-subtle": {
    "value": "var(--colors-charcoal-200)",
    "variable": "var(--colors-background-decorative-neutral-base-subtle)"
  },
  "colors.background.decorative.neutral.base-bold": {
    "value": "var(--colors-charcoal-500)",
    "variable": "var(--colors-background-decorative-neutral-base-bold)"
  },
  "colors.background.decorative.neutral.base-bolder": {
    "value": "var(--colors-charcoal-800)",
    "variable": "var(--colors-background-decorative-neutral-base-bolder)"
  },
  "colors.background.decorative.neutral.hover-subtle": {
    "value": "var(--colors-charcoal-300)",
    "variable": "var(--colors-background-decorative-neutral-hover-subtle)"
  },
  "colors.background.decorative.neutral.hover-bold": {
    "value": "var(--colors-charcoal-600)",
    "variable": "var(--colors-background-decorative-neutral-hover-bold)"
  },
  "colors.background.decorative.neutral.hover-bolder": {
    "value": "var(--colors-charcoal-900)",
    "variable": "var(--colors-background-decorative-neutral-hover-bolder)"
  },
  "colors.background.decorative.red.base-subtle": {
    "value": "var(--colors-red-100)",
    "variable": "var(--colors-background-decorative-red-base-subtle)"
  },
  "colors.background.decorative.red.base-bold": {
    "value": "var(--colors-red-500)",
    "variable": "var(--colors-background-decorative-red-base-bold)"
  },
  "colors.background.decorative.red.base-bolder": {
    "value": "var(--colors-red-700)",
    "variable": "var(--colors-background-decorative-red-base-bolder)"
  },
  "colors.background.decorative.red.hover-subtle": {
    "value": "var(--colors-red-200)",
    "variable": "var(--colors-background-decorative-red-hover-subtle)"
  },
  "colors.background.decorative.red.hover-bold": {
    "value": "var(--colors-red-600)",
    "variable": "var(--colors-background-decorative-red-hover-bold)"
  },
  "colors.background.decorative.red.hover-bolder": {
    "value": "var(--colors-red-800)",
    "variable": "var(--colors-background-decorative-red-hover-bolder)"
  },
  "colors.background.decorative.orange.base-subtle": {
    "value": "var(--colors-orange-100)",
    "variable": "var(--colors-background-decorative-orange-base-subtle)"
  },
  "colors.background.decorative.orange.base-bold": {
    "value": "var(--colors-orange-500)",
    "variable": "var(--colors-background-decorative-orange-base-bold)"
  },
  "colors.background.decorative.orange.base-bolder": {
    "value": "var(--colors-orange-700)",
    "variable": "var(--colors-background-decorative-orange-base-bolder)"
  },
  "colors.background.decorative.orange.hover-subtle": {
    "value": "var(--colors-orange-200)",
    "variable": "var(--colors-background-decorative-orange-hover-subtle)"
  },
  "colors.background.decorative.orange.hover-bold": {
    "value": "var(--colors-orange-600)",
    "variable": "var(--colors-background-decorative-orange-hover-bold)"
  },
  "colors.background.decorative.orange.hover-bolder": {
    "value": "var(--colors-orange-800)",
    "variable": "var(--colors-background-decorative-orange-hover-bolder)"
  },
  "colors.background.decorative.yellow.base-subtle": {
    "value": "var(--colors-yellow-100)",
    "variable": "var(--colors-background-decorative-yellow-base-subtle)"
  },
  "colors.background.decorative.yellow.base-bold": {
    "value": "var(--colors-yellow-500)",
    "variable": "var(--colors-background-decorative-yellow-base-bold)"
  },
  "colors.background.decorative.yellow.base-bolder": {
    "value": "var(--colors-yellow-700)",
    "variable": "var(--colors-background-decorative-yellow-base-bolder)"
  },
  "colors.background.decorative.yellow.hover-subtle": {
    "value": "var(--colors-yellow-200)",
    "variable": "var(--colors-background-decorative-yellow-hover-subtle)"
  },
  "colors.background.decorative.yellow.hover-bold": {
    "value": "var(--colors-yellow-600)",
    "variable": "var(--colors-background-decorative-yellow-hover-bold)"
  },
  "colors.background.decorative.yellow.hover-bolder": {
    "value": "var(--colors-yellow-800)",
    "variable": "var(--colors-background-decorative-yellow-hover-bolder)"
  },
  "colors.background.decorative.green.base-subtle": {
    "value": "var(--colors-green-100)",
    "variable": "var(--colors-background-decorative-green-base-subtle)"
  },
  "colors.background.decorative.green.base-bold": {
    "value": "var(--colors-green-500)",
    "variable": "var(--colors-background-decorative-green-base-bold)"
  },
  "colors.background.decorative.green.base-bolder": {
    "value": "var(--colors-green-700)",
    "variable": "var(--colors-background-decorative-green-base-bolder)"
  },
  "colors.background.decorative.green.hover-subtle": {
    "value": "var(--colors-green-200)",
    "variable": "var(--colors-background-decorative-green-hover-subtle)"
  },
  "colors.background.decorative.green.hover-bold": {
    "value": "var(--colors-green-600)",
    "variable": "var(--colors-background-decorative-green-hover-bold)"
  },
  "colors.background.decorative.green.hover-bolder": {
    "value": "var(--colors-green-800)",
    "variable": "var(--colors-background-decorative-green-hover-bolder)"
  },
  "colors.background.decorative.blue.base-subtle": {
    "value": "var(--colors-blue-100)",
    "variable": "var(--colors-background-decorative-blue-base-subtle)"
  },
  "colors.background.decorative.blue.base-bold": {
    "value": "var(--colors-blue-500)",
    "variable": "var(--colors-background-decorative-blue-base-bold)"
  },
  "colors.background.decorative.blue.base-bolder": {
    "value": "var(--colors-blue-700)",
    "variable": "var(--colors-background-decorative-blue-base-bolder)"
  },
  "colors.background.decorative.blue.hover-subtle": {
    "value": "var(--colors-blue-200)",
    "variable": "var(--colors-background-decorative-blue-hover-subtle)"
  },
  "colors.background.decorative.blue.hover-bold": {
    "value": "var(--colors-blue-600)",
    "variable": "var(--colors-background-decorative-blue-hover-bold)"
  },
  "colors.background.decorative.blue.hover-bolder": {
    "value": "var(--colors-blue-800)",
    "variable": "var(--colors-background-decorative-blue-hover-bolder)"
  },
  "colors.background.decorative.purple.base-subtle": {
    "value": "var(--colors-purple-100)",
    "variable": "var(--colors-background-decorative-purple-base-subtle)"
  },
  "colors.background.decorative.purple.base-bold": {
    "value": "var(--colors-purple-500)",
    "variable": "var(--colors-background-decorative-purple-base-bold)"
  },
  "colors.background.decorative.purple.base-bolder": {
    "value": "var(--colors-purple-700)",
    "variable": "var(--colors-background-decorative-purple-base-bolder)"
  },
  "colors.background.decorative.purple.hover-subtle": {
    "value": "var(--colors-purple-200)",
    "variable": "var(--colors-background-decorative-purple-hover-subtle)"
  },
  "colors.background.decorative.purple.hover-bold": {
    "value": "var(--colors-purple-600)",
    "variable": "var(--colors-background-decorative-purple-hover-bold)"
  },
  "colors.background.decorative.purple.hover-bolder": {
    "value": "var(--colors-purple-800)",
    "variable": "var(--colors-background-decorative-purple-hover-bolder)"
  },
  "colors.background.decorative.pink.base-subtle": {
    "value": "var(--colors-pink-100)",
    "variable": "var(--colors-background-decorative-pink-base-subtle)"
  },
  "colors.background.decorative.pink.base-bold": {
    "value": "var(--colors-pink-500)",
    "variable": "var(--colors-background-decorative-pink-base-bold)"
  },
  "colors.background.decorative.pink.base-bolder": {
    "value": "var(--colors-pink-700)",
    "variable": "var(--colors-background-decorative-pink-base-bolder)"
  },
  "colors.background.decorative.pink.hover-subtle": {
    "value": "var(--colors-pink-200)",
    "variable": "var(--colors-background-decorative-pink-hover-subtle)"
  },
  "colors.background.decorative.pink.hover-bold": {
    "value": "var(--colors-pink-600)",
    "variable": "var(--colors-background-decorative-pink-hover-bold)"
  },
  "colors.background.decorative.pink.hover-bolder": {
    "value": "var(--colors-pink-800)",
    "variable": "var(--colors-background-decorative-pink-hover-bolder)"
  },
  "colors.content.brand.bold": {
    "value": "var(--colors-content-brand-bold)",
    "variable": "var(--colors-content-brand-bold)"
  },
  "colors.content.brand.bold-hover": {
    "value": "var(--colors-content-brand-bold-hover)",
    "variable": "var(--colors-content-brand-bold-hover)"
  },
  "colors.content.brand.subtle": {
    "value": "var(--colors-content-brand-subtle)",
    "variable": "var(--colors-content-brand-subtle)"
  },
  "colors.content.brand.subtlest": {
    "value": "var(--colors-content-brand-subtlest)",
    "variable": "var(--colors-content-brand-subtlest)"
  },
  "colors.content.brand.reverse-subtle": {
    "value": "var(--colors-kale)",
    "variable": "var(--colors-content-brand-reverse-subtle)"
  },
  "colors.content.brand.reverse-bold": {
    "value": "var(--colors-digital-matcha)",
    "variable": "var(--colors-content-brand-reverse-bold)"
  },
  "colors.content.ai": {
    "value": "linear-gradient(45deg, var(--colors-purple-600) 0%, var(--colors-pink-600) 100%)",
    "variable": "var(--colors-content-ai)"
  },
  "colors.content.ai-hover": {
    "value": "linear-gradient(45deg, var(--colors-purple-700) 0%, var(--colors-pink-700) 100%)",
    "variable": "var(--colors-content-ai-hover)"
  },
  "colors.content.neutral.base.bold": {
    "value": "var(--colors-charcoal-700)",
    "variable": "var(--colors-content-neutral-base-bold)"
  },
  "colors.content.neutral.base.bold-hover": {
    "value": "var(--colors-charcoal-800)",
    "variable": "var(--colors-content-neutral-base-bold-hover)"
  },
  "colors.content.neutral.base.subtle": {
    "value": "var(--colors-charcoal-600)",
    "variable": "var(--colors-content-neutral-base-subtle)"
  },
  "colors.content.neutral.base.subtlest": {
    "value": "var(--colors-charcoal-500)",
    "variable": "var(--colors-content-neutral-base-subtlest)"
  },
  "colors.content.neutral.base.disable": {
    "value": "var(--colors-charcoal-400)",
    "variable": "var(--colors-content-neutral-base-disable)"
  },
  "colors.content.neutral.reverse.bold": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-content-neutral-reverse-bold)"
  },
  "colors.content.neutral.reverse.bold-hover": {
    "value": "#FFFFFFE0",
    "variable": "var(--colors-content-neutral-reverse-bold-hover)"
  },
  "colors.content.neutral.reverse.subtle": {
    "value": "#FFFFFFCC",
    "variable": "var(--colors-content-neutral-reverse-subtle)"
  },
  "colors.content.neutral.reverse.subtlest": {
    "value": "#FFFFFFA3",
    "variable": "var(--colors-content-neutral-reverse-subtlest)"
  },
  "colors.content.neutral.reverse.disable": {
    "value": "#FFFFFF52",
    "variable": "var(--colors-content-neutral-reverse-disable)"
  },
  "colors.content.functional.error": {
    "value": "var(--colors-red-600)",
    "variable": "var(--colors-content-functional-error)"
  },
  "colors.content.functional.warning": {
    "value": "var(--colors-orange-600)",
    "variable": "var(--colors-content-functional-warning)"
  },
  "colors.content.functional.sucess": {
    "value": "var(--colors-green-600)",
    "variable": "var(--colors-content-functional-sucess)"
  },
  "colors.content.functional.info": {
    "value": "var(--colors-blue-600)",
    "variable": "var(--colors-content-functional-info)"
  },
  "colors.content.decorative.red": {
    "value": "var(--colors-red-800)",
    "variable": "var(--colors-content-decorative-red)"
  },
  "colors.content.decorative.orange": {
    "value": "var(--colors-orange-800)",
    "variable": "var(--colors-content-decorative-orange)"
  },
  "colors.content.decorative.yellow": {
    "value": "var(--colors-yellow-800)",
    "variable": "var(--colors-content-decorative-yellow)"
  },
  "colors.content.decorative.green": {
    "value": "var(--colors-green-800)",
    "variable": "var(--colors-content-decorative-green)"
  },
  "colors.content.decorative.blue": {
    "value": "var(--colors-blue-800)",
    "variable": "var(--colors-content-decorative-blue)"
  },
  "colors.content.decorative.purple": {
    "value": "var(--colors-purple-800)",
    "variable": "var(--colors-content-decorative-purple)"
  },
  "colors.content.decorative.pink": {
    "value": "var(--colors-pink-800)",
    "variable": "var(--colors-content-decorative-pink)"
  },
  "colors.border.brand-subtle": {
    "value": "var(--colors-olive-100)",
    "variable": "var(--colors-border-brand-subtle)"
  },
  "colors.border.brand-bold": {
    "value": "var(--colors-olive-700)",
    "variable": "var(--colors-border-brand-bold)"
  },
  "colors.border.brand-bolder": {
    "value": "var(--colors-olive-900)",
    "variable": "var(--colors-border-brand-bolder)"
  },
  "colors.border.brand-complementary": {
    "value": "var(--colors-oat)",
    "variable": "var(--colors-border-brand-complementary)"
  },
  "colors.border.brand-complementary-reversed": {
    "value": "var(--colors-digital-matcha)",
    "variable": "var(--colors-border-brand-complementary-reversed)"
  },
  "colors.border.ai": {
    "value": "linear-gradient(45deg, var(--colors-purple-300) 0%, var(--colors-pink-300) 100%)",
    "variable": "var(--colors-border-ai)"
  },
  "colors.border.ai-hover": {
    "value": "linear-gradient(45deg, var(--colors-purple-400) 0%, var(--colors-pink-400) 100%)",
    "variable": "var(--colors-border-ai-hover)"
  },
  "colors.border.neutral.subtlest": {
    "value": "var(--colors-white)",
    "variable": "var(--colors-border-neutral-subtlest)"
  },
  "colors.border.neutral.subtle": {
    "value": "var(--colors-charcoal-200)",
    "variable": "var(--colors-border-neutral-subtle)"
  },
  "colors.border.neutral.bold": {
    "value": "var(--colors-charcoal-300)",
    "variable": "var(--colors-border-neutral-bold)"
  },
  "colors.border.neutral.bold-hover": {
    "value": "var(--colors-charcoal-400)",
    "variable": "var(--colors-border-neutral-bold-hover)"
  },
  "colors.border.neutral.bolder": {
    "value": "var(--colors-charcoal-800)",
    "variable": "var(--colors-border-neutral-bolder)"
  },
  "colors.border.functional.error": {
    "value": "var(--colors-red-400)",
    "variable": "var(--colors-border-functional-error)"
  },
  "colors.border.functional.warning": {
    "value": "var(--colors-orange-400)",
    "variable": "var(--colors-border-functional-warning)"
  },
  "colors.border.functional.success": {
    "value": "var(--colors-green-400)",
    "variable": "var(--colors-border-functional-success)"
  },
  "colors.border.functional.info": {
    "value": "var(--colors-blue-400)",
    "variable": "var(--colors-border-functional-info)"
  },
  "colors.border.decorative.neutral": {
    "value": "var(--colors-charcoal-300)",
    "variable": "var(--colors-border-decorative-neutral)"
  },
  "colors.border.decorative.red": {
    "value": "var(--colors-red-300)",
    "variable": "var(--colors-border-decorative-red)"
  },
  "colors.border.decorative.orange": {
    "value": "var(--colors-orange-300)",
    "variable": "var(--colors-border-decorative-orange)"
  },
  "colors.border.decorative.yellow": {
    "value": "var(--colors-yellow-300)",
    "variable": "var(--colors-border-decorative-yellow)"
  },
  "colors.border.decorative.green": {
    "value": "var(--colors-green-300)",
    "variable": "var(--colors-border-decorative-green)"
  },
  "colors.border.decorative.blue": {
    "value": "var(--colors-blue-300)",
    "variable": "var(--colors-border-decorative-blue)"
  },
  "colors.border.decorative.purple": {
    "value": "var(--colors-purple-300)",
    "variable": "var(--colors-border-decorative-purple)"
  },
  "colors.border.decorative.pink": {
    "value": "var(--colors-pink-300)",
    "variable": "var(--colors-border-decorative-pink)"
  },
  "colors.focus-ring.brand": {
    "value": "var(--colors-olive-200)",
    "variable": "var(--colors-focus-ring-brand)"
  },
  "colors.focus-ring.ai": {
    "value": "var(--colors-purple-100)",
    "variable": "var(--colors-focus-ring-ai)"
  },
  "colors.focus-ring.error": {
    "value": "var(--colors-red-200)",
    "variable": "var(--colors-focus-ring-error)"
  },
  "colors.focus-ring.warning": {
    "value": "var(--colors-orange-200)",
    "variable": "var(--colors-focus-ring-warning)"
  },
  "colors.focus-ring.success": {
    "value": "var(--colors-green-200)",
    "variable": "var(--colors-focus-ring-success)"
  },
  "colors.focus-ring.info": {
    "value": "var(--colors-blue-200)",
    "variable": "var(--colors-focus-ring-info)"
  },
  "colors.shadow.bold": {
    "value": "var(--colors-charcoal-800)",
    "variable": "var(--colors-shadow-bold)"
  },
  "colors.shadow.subtle": {
    "value": "var(--colors-charcoal-100)",
    "variable": "var(--colors-shadow-subtle)"
  },
  "colors.shadow.ai.bold": {
    "value": "var(--colors-purple-800)",
    "variable": "var(--colors-shadow-ai-bold)"
  },
  "colors.shadow.ai.subtle": {
    "value": "var(--colors-pink-300)",
    "variable": "var(--colors-shadow-ai-subtle)"
  },
  "colors.restaurant": {
    "value": "#FFFFFF",
    "variable": "var(--colors-restaurant)"
  },
  "colors.restaurant.rgb": {
    "value": "var(--colors-restaurant-rgb)",
    "variable": "var(--colors-restaurant-rgb)"
  },
  "colors.restaurant.darker": {
    "value": "color-mix(in srgb, #000000 7%, var(--colors-restaurant))",
    "variable": "var(--colors-restaurant-darker)"
  },
  "colors.restaurant.lighter": {
    "value": "color-mix(in srgb, #FFFFFF 7%, var(--colors-restaurant))",
    "variable": "var(--colors-restaurant-lighter)"
  },
  "colors.slotStatus.available": {
    "value": "#1ED800",
    "variable": "var(--colors-slot-status-available)"
  },
  "colors.slotStatus.squeeze": {
    "value": "#FF9900",
    "variable": "var(--colors-slot-status-squeeze)"
  },
  "borders.slotStatus": {
    "value": "1px solid var(--colors-transparent-background-30)",
    "variable": "var(--borders-slot-status)"
  },
  "fontSizes.icon.base": {
    "value": "var(--sizes-xs)",
    "variable": "var(--font-sizes-icon-base)"
  },
  "spacing.padding.sdk": {
    "value": "var(--spacing-padding-4)",
    "variable": "var(--spacing-padding-sdk)"
  },
  "spacing.gap.-0": {
    "value": "calc(var(--spacing-gap-0) * -1)",
    "variable": "var(--spacing-gap-0)"
  },
  "spacing.gap.-1": {
    "value": "calc(var(--spacing-gap-1) * -1)",
    "variable": "var(--spacing-gap-1)"
  },
  "spacing.gap.-2": {
    "value": "calc(var(--spacing-gap-2) * -1)",
    "variable": "var(--spacing-gap-2)"
  },
  "spacing.gap.-4": {
    "value": "calc(var(--spacing-gap-4) * -1)",
    "variable": "var(--spacing-gap-4)"
  },
  "spacing.gap.-6": {
    "value": "calc(var(--spacing-gap-6) * -1)",
    "variable": "var(--spacing-gap-6)"
  },
  "spacing.gap.-8": {
    "value": "calc(var(--spacing-gap-8) * -1)",
    "variable": "var(--spacing-gap-8)"
  },
  "spacing.gap.-20": {
    "value": "calc(var(--spacing-gap-20) * -1)",
    "variable": "var(--spacing-gap-20)"
  },
  "spacing.gap.-0,5": {
    "value": "calc(var(--spacing-gap-0\\,5) * -1)",
    "variable": "var(--spacing-gap-0\\,5)"
  },
  "spacing.padding.-1": {
    "value": "calc(var(--spacing-padding-1) * -1)",
    "variable": "var(--spacing-padding-1)"
  },
  "spacing.padding.-2": {
    "value": "calc(var(--spacing-padding-2) * -1)",
    "variable": "var(--spacing-padding-2)"
  },
  "spacing.padding.-3": {
    "value": "calc(var(--spacing-padding-3) * -1)",
    "variable": "var(--spacing-padding-3)"
  },
  "spacing.padding.-4": {
    "value": "calc(var(--spacing-padding-4) * -1)",
    "variable": "var(--spacing-padding-4)"
  },
  "spacing.padding.-6": {
    "value": "calc(var(--spacing-padding-6) * -1)",
    "variable": "var(--spacing-padding-6)"
  },
  "spacing.padding.-10": {
    "value": "calc(var(--spacing-padding-10) * -1)",
    "variable": "var(--spacing-padding-10)"
  },
  "spacing.padding.-0,5": {
    "value": "calc(var(--spacing-padding-0\\,5) * -1)",
    "variable": "var(--spacing-padding-0\\,5)"
  },
  "spacing.padding.-1,5": {
    "value": "calc(var(--spacing-padding-1\\,5) * -1)",
    "variable": "var(--spacing-padding-1\\,5)"
  },
  "spacing.margin.-4": {
    "value": "calc(var(--spacing-margin-4) * -1)",
    "variable": "var(--spacing-margin-4)"
  },
  "spacing.margin.-6": {
    "value": "calc(var(--spacing-margin-6) * -1)",
    "variable": "var(--spacing-margin-6)"
  },
  "spacing.margin.-10": {
    "value": "calc(var(--spacing-margin-10) * -1)",
    "variable": "var(--spacing-margin-10)"
  },
  "spacing.margin.-16": {
    "value": "calc(var(--spacing-margin-16) * -1)",
    "variable": "var(--spacing-margin-16)"
  },
  "spacing.margin.-20": {
    "value": "calc(var(--spacing-margin-20) * -1)",
    "variable": "var(--spacing-margin-20)"
  },
  "spacing.padding.-sdk": {
    "value": "calc(var(--spacing-padding-sdk) * -1)",
    "variable": "var(--spacing-padding-sdk)"
  },
  "colors.lightdark.text": {
    "value": "var(--colors-lightdark-text)",
    "variable": "var(--colors-lightdark-text)"
  },
  "colors.lightdark.text.opposite": {
    "value": "var(--colors-lightdark-text-opposite)",
    "variable": "var(--colors-lightdark-text-opposite)"
  },
  "colors.transparent.background.7": {
    "value": "var(--colors-transparent-background-7)",
    "variable": "var(--colors-transparent-background-7)"
  },
  "colors.transparent.background.10": {
    "value": "var(--colors-transparent-background-10)",
    "variable": "var(--colors-transparent-background-10)"
  },
  "colors.transparent.background.15": {
    "value": "var(--colors-transparent-background-15)",
    "variable": "var(--colors-transparent-background-15)"
  },
  "colors.transparent.background.20": {
    "value": "var(--colors-transparent-background-20)",
    "variable": "var(--colors-transparent-background-20)"
  },
  "colors.transparent.background.30": {
    "value": "var(--colors-transparent-background-30)",
    "variable": "var(--colors-transparent-background-30)"
  },
  "colors.transparent.background.100": {
    "value": "var(--colors-transparent-background-100)",
    "variable": "var(--colors-transparent-background-100)"
  },
  "colors.transparent.text.30": {
    "value": "var(--colors-transparent-text-30)",
    "variable": "var(--colors-transparent-text-30)"
  },
  "colors.transparent.text.60": {
    "value": "var(--colors-transparent-text-60)",
    "variable": "var(--colors-transparent-text-60)"
  },
  "colors.transparent.text.70": {
    "value": "var(--colors-transparent-text-70)",
    "variable": "var(--colors-transparent-text-70)"
  },
  "colors.transparent.border.20": {
    "value": "var(--colors-transparent-border-20)",
    "variable": "var(--colors-transparent-border-20)"
  },
  "colors.transparent.border": {
    "value": "var(--colors-transparent-border)",
    "variable": "var(--colors-transparent-border)"
  },
  "colors.whiteLabel.neutral": {
    "value": "var(--colors-white-label-neutral)",
    "variable": "var(--colors-white-label-neutral)"
  },
  "colors.whiteLabel.neutral.rgb": {
    "value": "var(--colors-white-label-neutral-rgb)",
    "variable": "var(--colors-white-label-neutral-rgb)"
  },
  "colors.content.brand.disabled": {
    "value": "var(--colors-content-brand-disabled)",
    "variable": "var(--colors-content-brand-disabled)"
  },
  "colors.content.neutral.bold": {
    "value": "var(--colors-content-neutral-bold)",
    "variable": "var(--colors-content-neutral-bold)"
  },
  "colors.content.neutral.bold-hover": {
    "value": "var(--colors-content-neutral-bold-hover)",
    "variable": "var(--colors-content-neutral-bold-hover)"
  },
  "colors.content.neutral.subtle": {
    "value": "var(--colors-content-neutral-subtle)",
    "variable": "var(--colors-content-neutral-subtle)"
  },
  "colors.content.neutral.subtlest": {
    "value": "var(--colors-content-neutral-subtlest)",
    "variable": "var(--colors-content-neutral-subtlest)"
  },
  "colors.content.neutral.disabled": {
    "value": "var(--colors-content-neutral-disabled)",
    "variable": "var(--colors-content-neutral-disabled)"
  },
  "colors.border.brand.brand-brand": {
    "value": "var(--colors-border-brand-brand-brand)",
    "variable": "var(--colors-border-brand-brand-brand)"
  },
  "colors.border.brand.brand-brand-hover": {
    "value": "var(--colors-border-brand-brand-brand-hover)",
    "variable": "var(--colors-border-brand-brand-brand-hover)"
  },
  "colors.border.brand.brand-subtle": {
    "value": "var(--colors-border-brand-brand-subtle)",
    "variable": "var(--colors-border-brand-brand-subtle)"
  },
  "colors.border.brand.brand-subtlest": {
    "value": "var(--colors-border-brand-brand-subtlest)",
    "variable": "var(--colors-border-brand-brand-subtlest)"
  },
  "colors.border.neutral-on-brand.bolder": {
    "value": "var(--colors-border-neutral-on-brand-bolder)",
    "variable": "var(--colors-border-neutral-on-brand-bolder)"
  },
  "colors.border.neutral-on-brand.bold-hover": {
    "value": "var(--colors-border-neutral-on-brand-bold-hover)",
    "variable": "var(--colors-border-neutral-on-brand-bold-hover)"
  },
  "colors.border.neutral-on-brand.bold": {
    "value": "var(--colors-border-neutral-on-brand-bold)",
    "variable": "var(--colors-border-neutral-on-brand-bold)"
  },
  "colors.border.neutral-on-brand.subtle": {
    "value": "var(--colors-border-neutral-on-brand-subtle)",
    "variable": "var(--colors-border-neutral-on-brand-subtle)"
  },
  "colors.border.neutral-on-brand.subtlest": {
    "value": "var(--colors-border-neutral-on-brand-subtlest)",
    "variable": "var(--colors-border-neutral-on-brand-subtlest)"
  },
  "colors.background.brand.disabled": {
    "value": "var(--colors-background-brand-disabled)",
    "variable": "var(--colors-background-brand-disabled)"
  },
  "colors.background.brand.low-contrast": {
    "value": "var(--colors-background-brand-low-contrast)",
    "variable": "var(--colors-background-brand-low-contrast)"
  },
  "colors.background.brand.high-contrast": {
    "value": "var(--colors-background-brand-high-contrast)",
    "variable": "var(--colors-background-brand-high-contrast)"
  },
  "colors.background.brand.adaptive": {
    "value": "var(--colors-background-brand-adaptive)",
    "variable": "var(--colors-background-brand-adaptive)"
  },
  "colors.background.neutral-on-brand.base.bold": {
    "value": "var(--colors-background-neutral-on-brand-base-bold)",
    "variable": "var(--colors-background-neutral-on-brand-base-bold)"
  },
  "colors.background.neutral-on-brand.base.bold-hover": {
    "value": "var(--colors-background-neutral-on-brand-base-bold-hover)",
    "variable": "var(--colors-background-neutral-on-brand-base-bold-hover)"
  },
  "colors.background.neutral-on-brand.base.subtle-hover": {
    "value": "var(--colors-background-neutral-on-brand-base-subtle-hover)",
    "variable": "var(--colors-background-neutral-on-brand-base-subtle-hover)"
  },
  "colors.background.neutral-on-brand.base.subtle": {
    "value": "var(--colors-background-neutral-on-brand-base-subtle)",
    "variable": "var(--colors-background-neutral-on-brand-base-subtle)"
  },
  "colors.background.neutral-on-brand.base.subtler-hover": {
    "value": "var(--colors-background-neutral-on-brand-base-subtler-hover)",
    "variable": "var(--colors-background-neutral-on-brand-base-subtler-hover)"
  },
  "colors.background.neutral-on-brand.base.subtler": {
    "value": "var(--colors-background-neutral-on-brand-base-subtler)",
    "variable": "var(--colors-background-neutral-on-brand-base-subtler)"
  },
  "colors.background.neutral-on-brand.base.subtlest-hover": {
    "value": "var(--colors-background-neutral-on-brand-base-subtlest-hover)",
    "variable": "var(--colors-background-neutral-on-brand-base-subtlest-hover)"
  },
  "colors.background.neutral-on-brand.base.subtlest": {
    "value": "var(--colors-background-neutral-on-brand-base-subtlest)",
    "variable": "var(--colors-background-neutral-on-brand-base-subtlest)"
  },
  "colors.background.neutral-on-brand.disabled.subtlest": {
    "value": "var(--colors-background-neutral-on-brand-disabled-subtlest)",
    "variable": "var(--colors-background-neutral-on-brand-disabled-subtlest)"
  },
  "colors.background.neutral-on-brand.disabled.subtle": {
    "value": "var(--colors-background-neutral-on-brand-disabled-subtle)",
    "variable": "var(--colors-background-neutral-on-brand-disabled-subtle)"
  },
  "colors.background.neutral-on-brand.disabled.bold": {
    "value": "var(--colors-background-neutral-on-brand-disabled-bold)",
    "variable": "var(--colors-background-neutral-on-brand-disabled-bold)"
  },
  "colors.background.neutral-on-brand.disabled.bolder": {
    "value": "var(--colors-background-neutral-on-brand-disabled-bolder)",
    "variable": "var(--colors-background-neutral-on-brand-disabled-bolder)"
  },
  "colors.background.neutral-on-brand.disabled.reversed": {
    "value": "var(--colors-background-neutral-on-brand-disabled-reversed)",
    "variable": "var(--colors-background-neutral-on-brand-disabled-reversed)"
  },
  "colors.shadow.brand": {
    "value": "var(--colors-shadow-brand)",
    "variable": "var(--colors-shadow-brand)"
  },
  "colors.shadow.neutral": {
    "value": "var(--colors-shadow-neutral)",
    "variable": "var(--colors-shadow-neutral)"
  },
  "shadows.effect.focus-ring.neutral": {
    "value": "var(--shadows-effect-focus-ring-neutral)",
    "variable": "var(--shadows-effect-focus-ring-neutral)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.7": {
    "value": "var(--colors-color-palette-7)",
    "variable": "var(--colors-color-palette-7)"
  },
  "colors.colorPalette.10": {
    "value": "var(--colors-color-palette-10)",
    "variable": "var(--colors-color-palette-10)"
  },
  "colors.colorPalette.15": {
    "value": "var(--colors-color-palette-15)",
    "variable": "var(--colors-color-palette-15)"
  },
  "colors.colorPalette.20": {
    "value": "var(--colors-color-palette-20)",
    "variable": "var(--colors-color-palette-20)"
  },
  "colors.colorPalette.30": {
    "value": "var(--colors-color-palette-30)",
    "variable": "var(--colors-color-palette-30)"
  },
  "colors.colorPalette.60": {
    "value": "var(--colors-color-palette-60)",
    "variable": "var(--colors-color-palette-60)"
  },
  "colors.colorPalette.70": {
    "value": "var(--colors-color-palette-70)",
    "variable": "var(--colors-color-palette-70)"
  },
  "colors.colorPalette.5": {
    "value": "var(--colors-color-palette-5)",
    "variable": "var(--colors-color-palette-5)"
  },
  "colors.colorPalette.brand.subtle": {
    "value": "var(--colors-color-palette-brand-subtle)",
    "variable": "var(--colors-color-palette-brand-subtle)"
  },
  "colors.colorPalette.subtle": {
    "value": "var(--colors-color-palette-subtle)",
    "variable": "var(--colors-color-palette-subtle)"
  },
  "colors.colorPalette.brand.subtle-hover": {
    "value": "var(--colors-color-palette-brand-subtle-hover)",
    "variable": "var(--colors-color-palette-brand-subtle-hover)"
  },
  "colors.colorPalette.subtle-hover": {
    "value": "var(--colors-color-palette-subtle-hover)",
    "variable": "var(--colors-color-palette-subtle-hover)"
  },
  "colors.colorPalette.brand.subtle-active": {
    "value": "var(--colors-color-palette-brand-subtle-active)",
    "variable": "var(--colors-color-palette-brand-subtle-active)"
  },
  "colors.colorPalette.subtle-active": {
    "value": "var(--colors-color-palette-subtle-active)",
    "variable": "var(--colors-color-palette-subtle-active)"
  },
  "colors.colorPalette.brand.bold": {
    "value": "var(--colors-color-palette-brand-bold)",
    "variable": "var(--colors-color-palette-brand-bold)"
  },
  "colors.colorPalette.bold": {
    "value": "var(--colors-color-palette-bold)",
    "variable": "var(--colors-color-palette-bold)"
  },
  "colors.colorPalette.brand.bold-hover": {
    "value": "var(--colors-color-palette-brand-bold-hover)",
    "variable": "var(--colors-color-palette-brand-bold-hover)"
  },
  "colors.colorPalette.bold-hover": {
    "value": "var(--colors-color-palette-bold-hover)",
    "variable": "var(--colors-color-palette-bold-hover)"
  },
  "colors.colorPalette.brand.bold-active": {
    "value": "var(--colors-color-palette-brand-bold-active)",
    "variable": "var(--colors-color-palette-brand-bold-active)"
  },
  "colors.colorPalette.bold-active": {
    "value": "var(--colors-color-palette-bold-active)",
    "variable": "var(--colors-color-palette-bold-active)"
  },
  "colors.colorPalette.brand.bolder": {
    "value": "var(--colors-color-palette-brand-bolder)",
    "variable": "var(--colors-color-palette-brand-bolder)"
  },
  "colors.colorPalette.bolder": {
    "value": "var(--colors-color-palette-bolder)",
    "variable": "var(--colors-color-palette-bolder)"
  },
  "colors.colorPalette.brand.bolder-hover": {
    "value": "var(--colors-color-palette-brand-bolder-hover)",
    "variable": "var(--colors-color-palette-brand-bolder-hover)"
  },
  "colors.colorPalette.bolder-hover": {
    "value": "var(--colors-color-palette-bolder-hover)",
    "variable": "var(--colors-color-palette-bolder-hover)"
  },
  "colors.colorPalette.brand.bolder-active": {
    "value": "var(--colors-color-palette-brand-bolder-active)",
    "variable": "var(--colors-color-palette-brand-bolder-active)"
  },
  "colors.colorPalette.bolder-active": {
    "value": "var(--colors-color-palette-bolder-active)",
    "variable": "var(--colors-color-palette-bolder-active)"
  },
  "colors.colorPalette.brand.complementary": {
    "value": "var(--colors-color-palette-brand-complementary)",
    "variable": "var(--colors-color-palette-brand-complementary)"
  },
  "colors.colorPalette.complementary": {
    "value": "var(--colors-color-palette-complementary)",
    "variable": "var(--colors-color-palette-complementary)"
  },
  "colors.colorPalette.brand.complementary-reversed": {
    "value": "var(--colors-color-palette-brand-complementary-reversed)",
    "variable": "var(--colors-color-palette-brand-complementary-reversed)"
  },
  "colors.colorPalette.complementary-reversed": {
    "value": "var(--colors-color-palette-complementary-reversed)",
    "variable": "var(--colors-color-palette-complementary-reversed)"
  },
  "colors.colorPalette.ai.subtler": {
    "value": "var(--colors-color-palette-ai-subtler)",
    "variable": "var(--colors-color-palette-ai-subtler)"
  },
  "colors.colorPalette.subtler": {
    "value": "var(--colors-color-palette-subtler)",
    "variable": "var(--colors-color-palette-subtler)"
  },
  "colors.colorPalette.ai.subtler-hover": {
    "value": "var(--colors-color-palette-ai-subtler-hover)",
    "variable": "var(--colors-color-palette-ai-subtler-hover)"
  },
  "colors.colorPalette.subtler-hover": {
    "value": "var(--colors-color-palette-subtler-hover)",
    "variable": "var(--colors-color-palette-subtler-hover)"
  },
  "colors.colorPalette.ai.subtler-active": {
    "value": "var(--colors-color-palette-ai-subtler-active)",
    "variable": "var(--colors-color-palette-ai-subtler-active)"
  },
  "colors.colorPalette.subtler-active": {
    "value": "var(--colors-color-palette-subtler-active)",
    "variable": "var(--colors-color-palette-subtler-active)"
  },
  "colors.colorPalette.ai.subtle": {
    "value": "var(--colors-color-palette-ai-subtle)",
    "variable": "var(--colors-color-palette-ai-subtle)"
  },
  "colors.colorPalette.ai.subtle-hover": {
    "value": "var(--colors-color-palette-ai-subtle-hover)",
    "variable": "var(--colors-color-palette-ai-subtle-hover)"
  },
  "colors.colorPalette.ai.subtle-active": {
    "value": "var(--colors-color-palette-ai-subtle-active)",
    "variable": "var(--colors-color-palette-ai-subtle-active)"
  },
  "colors.colorPalette.ai.bold": {
    "value": "var(--colors-color-palette-ai-bold)",
    "variable": "var(--colors-color-palette-ai-bold)"
  },
  "colors.colorPalette.ai.bold-hover": {
    "value": "var(--colors-color-palette-ai-bold-hover)",
    "variable": "var(--colors-color-palette-ai-bold-hover)"
  },
  "colors.colorPalette.ai.bold-active": {
    "value": "var(--colors-color-palette-ai-bold-active)",
    "variable": "var(--colors-color-palette-ai-bold-active)"
  },
  "colors.colorPalette.neutral.base.subtlest": {
    "value": "var(--colors-color-palette-neutral-base-subtlest)",
    "variable": "var(--colors-color-palette-neutral-base-subtlest)"
  },
  "colors.colorPalette.base.subtlest": {
    "value": "var(--colors-color-palette-base-subtlest)",
    "variable": "var(--colors-color-palette-base-subtlest)"
  },
  "colors.colorPalette.subtlest": {
    "value": "var(--colors-color-palette-subtlest)",
    "variable": "var(--colors-color-palette-subtlest)"
  },
  "colors.colorPalette.neutral.base.subtlest-hover": {
    "value": "var(--colors-color-palette-neutral-base-subtlest-hover)",
    "variable": "var(--colors-color-palette-neutral-base-subtlest-hover)"
  },
  "colors.colorPalette.base.subtlest-hover": {
    "value": "var(--colors-color-palette-base-subtlest-hover)",
    "variable": "var(--colors-color-palette-base-subtlest-hover)"
  },
  "colors.colorPalette.subtlest-hover": {
    "value": "var(--colors-color-palette-subtlest-hover)",
    "variable": "var(--colors-color-palette-subtlest-hover)"
  },
  "colors.colorPalette.neutral.base.subtlest-active": {
    "value": "var(--colors-color-palette-neutral-base-subtlest-active)",
    "variable": "var(--colors-color-palette-neutral-base-subtlest-active)"
  },
  "colors.colorPalette.base.subtlest-active": {
    "value": "var(--colors-color-palette-base-subtlest-active)",
    "variable": "var(--colors-color-palette-base-subtlest-active)"
  },
  "colors.colorPalette.subtlest-active": {
    "value": "var(--colors-color-palette-subtlest-active)",
    "variable": "var(--colors-color-palette-subtlest-active)"
  },
  "colors.colorPalette.neutral.base.subtler": {
    "value": "var(--colors-color-palette-neutral-base-subtler)",
    "variable": "var(--colors-color-palette-neutral-base-subtler)"
  },
  "colors.colorPalette.base.subtler": {
    "value": "var(--colors-color-palette-base-subtler)",
    "variable": "var(--colors-color-palette-base-subtler)"
  },
  "colors.colorPalette.neutral.base.subtler-hover": {
    "value": "var(--colors-color-palette-neutral-base-subtler-hover)",
    "variable": "var(--colors-color-palette-neutral-base-subtler-hover)"
  },
  "colors.colorPalette.base.subtler-hover": {
    "value": "var(--colors-color-palette-base-subtler-hover)",
    "variable": "var(--colors-color-palette-base-subtler-hover)"
  },
  "colors.colorPalette.neutral.base.subtler-active": {
    "value": "var(--colors-color-palette-neutral-base-subtler-active)",
    "variable": "var(--colors-color-palette-neutral-base-subtler-active)"
  },
  "colors.colorPalette.base.subtler-active": {
    "value": "var(--colors-color-palette-base-subtler-active)",
    "variable": "var(--colors-color-palette-base-subtler-active)"
  },
  "colors.colorPalette.neutral.base.subtle": {
    "value": "var(--colors-color-palette-neutral-base-subtle)",
    "variable": "var(--colors-color-palette-neutral-base-subtle)"
  },
  "colors.colorPalette.base.subtle": {
    "value": "var(--colors-color-palette-base-subtle)",
    "variable": "var(--colors-color-palette-base-subtle)"
  },
  "colors.colorPalette.neutral.base.subtle-hover": {
    "value": "var(--colors-color-palette-neutral-base-subtle-hover)",
    "variable": "var(--colors-color-palette-neutral-base-subtle-hover)"
  },
  "colors.colorPalette.base.subtle-hover": {
    "value": "var(--colors-color-palette-base-subtle-hover)",
    "variable": "var(--colors-color-palette-base-subtle-hover)"
  },
  "colors.colorPalette.neutral.base.subtle-active": {
    "value": "var(--colors-color-palette-neutral-base-subtle-active)",
    "variable": "var(--colors-color-palette-neutral-base-subtle-active)"
  },
  "colors.colorPalette.base.subtle-active": {
    "value": "var(--colors-color-palette-base-subtle-active)",
    "variable": "var(--colors-color-palette-base-subtle-active)"
  },
  "colors.colorPalette.neutral.base.bold": {
    "value": "var(--colors-color-palette-neutral-base-bold)",
    "variable": "var(--colors-color-palette-neutral-base-bold)"
  },
  "colors.colorPalette.base.bold": {
    "value": "var(--colors-color-palette-base-bold)",
    "variable": "var(--colors-color-palette-base-bold)"
  },
  "colors.colorPalette.neutral.base.bold-hover": {
    "value": "var(--colors-color-palette-neutral-base-bold-hover)",
    "variable": "var(--colors-color-palette-neutral-base-bold-hover)"
  },
  "colors.colorPalette.base.bold-hover": {
    "value": "var(--colors-color-palette-base-bold-hover)",
    "variable": "var(--colors-color-palette-base-bold-hover)"
  },
  "colors.colorPalette.neutral.base.bold-active": {
    "value": "var(--colors-color-palette-neutral-base-bold-active)",
    "variable": "var(--colors-color-palette-neutral-base-bold-active)"
  },
  "colors.colorPalette.base.bold-active": {
    "value": "var(--colors-color-palette-base-bold-active)",
    "variable": "var(--colors-color-palette-base-bold-active)"
  },
  "colors.colorPalette.neutral.disable.subtlest": {
    "value": "var(--colors-color-palette-neutral-disable-subtlest)",
    "variable": "var(--colors-color-palette-neutral-disable-subtlest)"
  },
  "colors.colorPalette.disable.subtlest": {
    "value": "var(--colors-color-palette-disable-subtlest)",
    "variable": "var(--colors-color-palette-disable-subtlest)"
  },
  "colors.colorPalette.neutral.disable.subtle": {
    "value": "var(--colors-color-palette-neutral-disable-subtle)",
    "variable": "var(--colors-color-palette-neutral-disable-subtle)"
  },
  "colors.colorPalette.disable.subtle": {
    "value": "var(--colors-color-palette-disable-subtle)",
    "variable": "var(--colors-color-palette-disable-subtle)"
  },
  "colors.colorPalette.neutral.disable.bold": {
    "value": "var(--colors-color-palette-neutral-disable-bold)",
    "variable": "var(--colors-color-palette-neutral-disable-bold)"
  },
  "colors.colorPalette.disable.bold": {
    "value": "var(--colors-color-palette-disable-bold)",
    "variable": "var(--colors-color-palette-disable-bold)"
  },
  "colors.colorPalette.neutral.disable.bolder": {
    "value": "var(--colors-color-palette-neutral-disable-bolder)",
    "variable": "var(--colors-color-palette-neutral-disable-bolder)"
  },
  "colors.colorPalette.disable.bolder": {
    "value": "var(--colors-color-palette-disable-bolder)",
    "variable": "var(--colors-color-palette-disable-bolder)"
  },
  "colors.colorPalette.neutral.disable.reverse": {
    "value": "var(--colors-color-palette-neutral-disable-reverse)",
    "variable": "var(--colors-color-palette-neutral-disable-reverse)"
  },
  "colors.colorPalette.disable.reverse": {
    "value": "var(--colors-color-palette-disable-reverse)",
    "variable": "var(--colors-color-palette-disable-reverse)"
  },
  "colors.colorPalette.reverse": {
    "value": "var(--colors-color-palette-reverse)",
    "variable": "var(--colors-color-palette-reverse)"
  },
  "colors.colorPalette.neutral.overlay.subtler": {
    "value": "var(--colors-color-palette-neutral-overlay-subtler)",
    "variable": "var(--colors-color-palette-neutral-overlay-subtler)"
  },
  "colors.colorPalette.overlay.subtler": {
    "value": "var(--colors-color-palette-overlay-subtler)",
    "variable": "var(--colors-color-palette-overlay-subtler)"
  },
  "colors.colorPalette.neutral.overlay.subtle": {
    "value": "var(--colors-color-palette-neutral-overlay-subtle)",
    "variable": "var(--colors-color-palette-neutral-overlay-subtle)"
  },
  "colors.colorPalette.overlay.subtle": {
    "value": "var(--colors-color-palette-overlay-subtle)",
    "variable": "var(--colors-color-palette-overlay-subtle)"
  },
  "colors.colorPalette.neutral.overlay.bold": {
    "value": "var(--colors-color-palette-neutral-overlay-bold)",
    "variable": "var(--colors-color-palette-neutral-overlay-bold)"
  },
  "colors.colorPalette.overlay.bold": {
    "value": "var(--colors-color-palette-overlay-bold)",
    "variable": "var(--colors-color-palette-overlay-bold)"
  },
  "colors.colorPalette.neutral.reverse.subtlest": {
    "value": "var(--colors-color-palette-neutral-reverse-subtlest)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtlest)"
  },
  "colors.colorPalette.reverse.subtlest": {
    "value": "var(--colors-color-palette-reverse-subtlest)",
    "variable": "var(--colors-color-palette-reverse-subtlest)"
  },
  "colors.colorPalette.neutral.reverse.subtlest-hover": {
    "value": "var(--colors-color-palette-neutral-reverse-subtlest-hover)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtlest-hover)"
  },
  "colors.colorPalette.reverse.subtlest-hover": {
    "value": "var(--colors-color-palette-reverse-subtlest-hover)",
    "variable": "var(--colors-color-palette-reverse-subtlest-hover)"
  },
  "colors.colorPalette.neutral.reverse.subtlest-active": {
    "value": "var(--colors-color-palette-neutral-reverse-subtlest-active)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtlest-active)"
  },
  "colors.colorPalette.reverse.subtlest-active": {
    "value": "var(--colors-color-palette-reverse-subtlest-active)",
    "variable": "var(--colors-color-palette-reverse-subtlest-active)"
  },
  "colors.colorPalette.neutral.reverse.subtler": {
    "value": "var(--colors-color-palette-neutral-reverse-subtler)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtler)"
  },
  "colors.colorPalette.reverse.subtler": {
    "value": "var(--colors-color-palette-reverse-subtler)",
    "variable": "var(--colors-color-palette-reverse-subtler)"
  },
  "colors.colorPalette.neutral.reverse.subtler-hover": {
    "value": "var(--colors-color-palette-neutral-reverse-subtler-hover)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtler-hover)"
  },
  "colors.colorPalette.reverse.subtler-hover": {
    "value": "var(--colors-color-palette-reverse-subtler-hover)",
    "variable": "var(--colors-color-palette-reverse-subtler-hover)"
  },
  "colors.colorPalette.neutral.reverse.subtler-active": {
    "value": "var(--colors-color-palette-neutral-reverse-subtler-active)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtler-active)"
  },
  "colors.colorPalette.reverse.subtler-active": {
    "value": "var(--colors-color-palette-reverse-subtler-active)",
    "variable": "var(--colors-color-palette-reverse-subtler-active)"
  },
  "colors.colorPalette.neutral.reverse.subtle": {
    "value": "var(--colors-color-palette-neutral-reverse-subtle)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtle)"
  },
  "colors.colorPalette.reverse.subtle": {
    "value": "var(--colors-color-palette-reverse-subtle)",
    "variable": "var(--colors-color-palette-reverse-subtle)"
  },
  "colors.colorPalette.neutral.reverse.subtle-hover": {
    "value": "var(--colors-color-palette-neutral-reverse-subtle-hover)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtle-hover)"
  },
  "colors.colorPalette.reverse.subtle-hover": {
    "value": "var(--colors-color-palette-reverse-subtle-hover)",
    "variable": "var(--colors-color-palette-reverse-subtle-hover)"
  },
  "colors.colorPalette.neutral.reverse.subtle-active": {
    "value": "var(--colors-color-palette-neutral-reverse-subtle-active)",
    "variable": "var(--colors-color-palette-neutral-reverse-subtle-active)"
  },
  "colors.colorPalette.reverse.subtle-active": {
    "value": "var(--colors-color-palette-reverse-subtle-active)",
    "variable": "var(--colors-color-palette-reverse-subtle-active)"
  },
  "colors.colorPalette.neutral.reverse.bold": {
    "value": "var(--colors-color-palette-neutral-reverse-bold)",
    "variable": "var(--colors-color-palette-neutral-reverse-bold)"
  },
  "colors.colorPalette.reverse.bold": {
    "value": "var(--colors-color-palette-reverse-bold)",
    "variable": "var(--colors-color-palette-reverse-bold)"
  },
  "colors.colorPalette.neutral.reverse.bold-hover": {
    "value": "var(--colors-color-palette-neutral-reverse-bold-hover)",
    "variable": "var(--colors-color-palette-neutral-reverse-bold-hover)"
  },
  "colors.colorPalette.reverse.bold-hover": {
    "value": "var(--colors-color-palette-reverse-bold-hover)",
    "variable": "var(--colors-color-palette-reverse-bold-hover)"
  },
  "colors.colorPalette.neutral.reverse.bold-active": {
    "value": "var(--colors-color-palette-neutral-reverse-bold-active)",
    "variable": "var(--colors-color-palette-neutral-reverse-bold-active)"
  },
  "colors.colorPalette.reverse.bold-active": {
    "value": "var(--colors-color-palette-reverse-bold-active)",
    "variable": "var(--colors-color-palette-reverse-bold-active)"
  },
  "colors.colorPalette.functional.error.subtle": {
    "value": "var(--colors-color-palette-functional-error-subtle)",
    "variable": "var(--colors-color-palette-functional-error-subtle)"
  },
  "colors.colorPalette.error.subtle": {
    "value": "var(--colors-color-palette-error-subtle)",
    "variable": "var(--colors-color-palette-error-subtle)"
  },
  "colors.colorPalette.functional.error.subtle-hover": {
    "value": "var(--colors-color-palette-functional-error-subtle-hover)",
    "variable": "var(--colors-color-palette-functional-error-subtle-hover)"
  },
  "colors.colorPalette.error.subtle-hover": {
    "value": "var(--colors-color-palette-error-subtle-hover)",
    "variable": "var(--colors-color-palette-error-subtle-hover)"
  },
  "colors.colorPalette.functional.error.subtle-active": {
    "value": "var(--colors-color-palette-functional-error-subtle-active)",
    "variable": "var(--colors-color-palette-functional-error-subtle-active)"
  },
  "colors.colorPalette.error.subtle-active": {
    "value": "var(--colors-color-palette-error-subtle-active)",
    "variable": "var(--colors-color-palette-error-subtle-active)"
  },
  "colors.colorPalette.functional.error.bold": {
    "value": "var(--colors-color-palette-functional-error-bold)",
    "variable": "var(--colors-color-palette-functional-error-bold)"
  },
  "colors.colorPalette.error.bold": {
    "value": "var(--colors-color-palette-error-bold)",
    "variable": "var(--colors-color-palette-error-bold)"
  },
  "colors.colorPalette.functional.error.bold-hover": {
    "value": "var(--colors-color-palette-functional-error-bold-hover)",
    "variable": "var(--colors-color-palette-functional-error-bold-hover)"
  },
  "colors.colorPalette.error.bold-hover": {
    "value": "var(--colors-color-palette-error-bold-hover)",
    "variable": "var(--colors-color-palette-error-bold-hover)"
  },
  "colors.colorPalette.functional.error.bold-active": {
    "value": "var(--colors-color-palette-functional-error-bold-active)",
    "variable": "var(--colors-color-palette-functional-error-bold-active)"
  },
  "colors.colorPalette.error.bold-active": {
    "value": "var(--colors-color-palette-error-bold-active)",
    "variable": "var(--colors-color-palette-error-bold-active)"
  },
  "colors.colorPalette.functional.warning.subtle": {
    "value": "var(--colors-color-palette-functional-warning-subtle)",
    "variable": "var(--colors-color-palette-functional-warning-subtle)"
  },
  "colors.colorPalette.warning.subtle": {
    "value": "var(--colors-color-palette-warning-subtle)",
    "variable": "var(--colors-color-palette-warning-subtle)"
  },
  "colors.colorPalette.functional.warning.subtle-hover": {
    "value": "var(--colors-color-palette-functional-warning-subtle-hover)",
    "variable": "var(--colors-color-palette-functional-warning-subtle-hover)"
  },
  "colors.colorPalette.warning.subtle-hover": {
    "value": "var(--colors-color-palette-warning-subtle-hover)",
    "variable": "var(--colors-color-palette-warning-subtle-hover)"
  },
  "colors.colorPalette.functional.warning.subtle-active": {
    "value": "var(--colors-color-palette-functional-warning-subtle-active)",
    "variable": "var(--colors-color-palette-functional-warning-subtle-active)"
  },
  "colors.colorPalette.warning.subtle-active": {
    "value": "var(--colors-color-palette-warning-subtle-active)",
    "variable": "var(--colors-color-palette-warning-subtle-active)"
  },
  "colors.colorPalette.functional.warning.bold": {
    "value": "var(--colors-color-palette-functional-warning-bold)",
    "variable": "var(--colors-color-palette-functional-warning-bold)"
  },
  "colors.colorPalette.warning.bold": {
    "value": "var(--colors-color-palette-warning-bold)",
    "variable": "var(--colors-color-palette-warning-bold)"
  },
  "colors.colorPalette.functional.warning.bold-hover": {
    "value": "var(--colors-color-palette-functional-warning-bold-hover)",
    "variable": "var(--colors-color-palette-functional-warning-bold-hover)"
  },
  "colors.colorPalette.warning.bold-hover": {
    "value": "var(--colors-color-palette-warning-bold-hover)",
    "variable": "var(--colors-color-palette-warning-bold-hover)"
  },
  "colors.colorPalette.functional.warning.bold-active": {
    "value": "var(--colors-color-palette-functional-warning-bold-active)",
    "variable": "var(--colors-color-palette-functional-warning-bold-active)"
  },
  "colors.colorPalette.warning.bold-active": {
    "value": "var(--colors-color-palette-warning-bold-active)",
    "variable": "var(--colors-color-palette-warning-bold-active)"
  },
  "colors.colorPalette.functional.success.subtle": {
    "value": "var(--colors-color-palette-functional-success-subtle)",
    "variable": "var(--colors-color-palette-functional-success-subtle)"
  },
  "colors.colorPalette.success.subtle": {
    "value": "var(--colors-color-palette-success-subtle)",
    "variable": "var(--colors-color-palette-success-subtle)"
  },
  "colors.colorPalette.functional.success.subtle-hover": {
    "value": "var(--colors-color-palette-functional-success-subtle-hover)",
    "variable": "var(--colors-color-palette-functional-success-subtle-hover)"
  },
  "colors.colorPalette.success.subtle-hover": {
    "value": "var(--colors-color-palette-success-subtle-hover)",
    "variable": "var(--colors-color-palette-success-subtle-hover)"
  },
  "colors.colorPalette.functional.success.subtle-active": {
    "value": "var(--colors-color-palette-functional-success-subtle-active)",
    "variable": "var(--colors-color-palette-functional-success-subtle-active)"
  },
  "colors.colorPalette.success.subtle-active": {
    "value": "var(--colors-color-palette-success-subtle-active)",
    "variable": "var(--colors-color-palette-success-subtle-active)"
  },
  "colors.colorPalette.functional.success.bold": {
    "value": "var(--colors-color-palette-functional-success-bold)",
    "variable": "var(--colors-color-palette-functional-success-bold)"
  },
  "colors.colorPalette.success.bold": {
    "value": "var(--colors-color-palette-success-bold)",
    "variable": "var(--colors-color-palette-success-bold)"
  },
  "colors.colorPalette.functional.success.bold-hover": {
    "value": "var(--colors-color-palette-functional-success-bold-hover)",
    "variable": "var(--colors-color-palette-functional-success-bold-hover)"
  },
  "colors.colorPalette.success.bold-hover": {
    "value": "var(--colors-color-palette-success-bold-hover)",
    "variable": "var(--colors-color-palette-success-bold-hover)"
  },
  "colors.colorPalette.functional.success.bold-active": {
    "value": "var(--colors-color-palette-functional-success-bold-active)",
    "variable": "var(--colors-color-palette-functional-success-bold-active)"
  },
  "colors.colorPalette.success.bold-active": {
    "value": "var(--colors-color-palette-success-bold-active)",
    "variable": "var(--colors-color-palette-success-bold-active)"
  },
  "colors.colorPalette.functional.info.subtle": {
    "value": "var(--colors-color-palette-functional-info-subtle)",
    "variable": "var(--colors-color-palette-functional-info-subtle)"
  },
  "colors.colorPalette.info.subtle": {
    "value": "var(--colors-color-palette-info-subtle)",
    "variable": "var(--colors-color-palette-info-subtle)"
  },
  "colors.colorPalette.functional.info.subtle-hover": {
    "value": "var(--colors-color-palette-functional-info-subtle-hover)",
    "variable": "var(--colors-color-palette-functional-info-subtle-hover)"
  },
  "colors.colorPalette.info.subtle-hover": {
    "value": "var(--colors-color-palette-info-subtle-hover)",
    "variable": "var(--colors-color-palette-info-subtle-hover)"
  },
  "colors.colorPalette.functional.info.subtle-active": {
    "value": "var(--colors-color-palette-functional-info-subtle-active)",
    "variable": "var(--colors-color-palette-functional-info-subtle-active)"
  },
  "colors.colorPalette.info.subtle-active": {
    "value": "var(--colors-color-palette-info-subtle-active)",
    "variable": "var(--colors-color-palette-info-subtle-active)"
  },
  "colors.colorPalette.functional.info.bold": {
    "value": "var(--colors-color-palette-functional-info-bold)",
    "variable": "var(--colors-color-palette-functional-info-bold)"
  },
  "colors.colorPalette.info.bold": {
    "value": "var(--colors-color-palette-info-bold)",
    "variable": "var(--colors-color-palette-info-bold)"
  },
  "colors.colorPalette.functional.info.bold-hover": {
    "value": "var(--colors-color-palette-functional-info-bold-hover)",
    "variable": "var(--colors-color-palette-functional-info-bold-hover)"
  },
  "colors.colorPalette.info.bold-hover": {
    "value": "var(--colors-color-palette-info-bold-hover)",
    "variable": "var(--colors-color-palette-info-bold-hover)"
  },
  "colors.colorPalette.functional.info.bold-active": {
    "value": "var(--colors-color-palette-functional-info-bold-active)",
    "variable": "var(--colors-color-palette-functional-info-bold-active)"
  },
  "colors.colorPalette.info.bold-active": {
    "value": "var(--colors-color-palette-info-bold-active)",
    "variable": "var(--colors-color-palette-info-bold-active)"
  },
  "colors.colorPalette.decorative.neutral.base-subtle": {
    "value": "var(--colors-color-palette-decorative-neutral-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-neutral-base-subtle)"
  },
  "colors.colorPalette.neutral.base-subtle": {
    "value": "var(--colors-color-palette-neutral-base-subtle)",
    "variable": "var(--colors-color-palette-neutral-base-subtle)"
  },
  "colors.colorPalette.base-subtle": {
    "value": "var(--colors-color-palette-base-subtle)",
    "variable": "var(--colors-color-palette-base-subtle)"
  },
  "colors.colorPalette.decorative.neutral.base-bold": {
    "value": "var(--colors-color-palette-decorative-neutral-base-bold)",
    "variable": "var(--colors-color-palette-decorative-neutral-base-bold)"
  },
  "colors.colorPalette.neutral.base-bold": {
    "value": "var(--colors-color-palette-neutral-base-bold)",
    "variable": "var(--colors-color-palette-neutral-base-bold)"
  },
  "colors.colorPalette.base-bold": {
    "value": "var(--colors-color-palette-base-bold)",
    "variable": "var(--colors-color-palette-base-bold)"
  },
  "colors.colorPalette.decorative.neutral.base-bolder": {
    "value": "var(--colors-color-palette-decorative-neutral-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-neutral-base-bolder)"
  },
  "colors.colorPalette.neutral.base-bolder": {
    "value": "var(--colors-color-palette-neutral-base-bolder)",
    "variable": "var(--colors-color-palette-neutral-base-bolder)"
  },
  "colors.colorPalette.base-bolder": {
    "value": "var(--colors-color-palette-base-bolder)",
    "variable": "var(--colors-color-palette-base-bolder)"
  },
  "colors.colorPalette.decorative.neutral.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-neutral-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-neutral-hover-subtle)"
  },
  "colors.colorPalette.neutral.hover-subtle": {
    "value": "var(--colors-color-palette-neutral-hover-subtle)",
    "variable": "var(--colors-color-palette-neutral-hover-subtle)"
  },
  "colors.colorPalette.hover-subtle": {
    "value": "var(--colors-color-palette-hover-subtle)",
    "variable": "var(--colors-color-palette-hover-subtle)"
  },
  "colors.colorPalette.decorative.neutral.hover-bold": {
    "value": "var(--colors-color-palette-decorative-neutral-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-neutral-hover-bold)"
  },
  "colors.colorPalette.neutral.hover-bold": {
    "value": "var(--colors-color-palette-neutral-hover-bold)",
    "variable": "var(--colors-color-palette-neutral-hover-bold)"
  },
  "colors.colorPalette.hover-bold": {
    "value": "var(--colors-color-palette-hover-bold)",
    "variable": "var(--colors-color-palette-hover-bold)"
  },
  "colors.colorPalette.decorative.neutral.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-neutral-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-neutral-hover-bolder)"
  },
  "colors.colorPalette.neutral.hover-bolder": {
    "value": "var(--colors-color-palette-neutral-hover-bolder)",
    "variable": "var(--colors-color-palette-neutral-hover-bolder)"
  },
  "colors.colorPalette.hover-bolder": {
    "value": "var(--colors-color-palette-hover-bolder)",
    "variable": "var(--colors-color-palette-hover-bolder)"
  },
  "colors.colorPalette.decorative.red.base-subtle": {
    "value": "var(--colors-color-palette-decorative-red-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-red-base-subtle)"
  },
  "colors.colorPalette.red.base-subtle": {
    "value": "var(--colors-color-palette-red-base-subtle)",
    "variable": "var(--colors-color-palette-red-base-subtle)"
  },
  "colors.colorPalette.decorative.red.base-bold": {
    "value": "var(--colors-color-palette-decorative-red-base-bold)",
    "variable": "var(--colors-color-palette-decorative-red-base-bold)"
  },
  "colors.colorPalette.red.base-bold": {
    "value": "var(--colors-color-palette-red-base-bold)",
    "variable": "var(--colors-color-palette-red-base-bold)"
  },
  "colors.colorPalette.decorative.red.base-bolder": {
    "value": "var(--colors-color-palette-decorative-red-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-red-base-bolder)"
  },
  "colors.colorPalette.red.base-bolder": {
    "value": "var(--colors-color-palette-red-base-bolder)",
    "variable": "var(--colors-color-palette-red-base-bolder)"
  },
  "colors.colorPalette.decorative.red.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-red-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-red-hover-subtle)"
  },
  "colors.colorPalette.red.hover-subtle": {
    "value": "var(--colors-color-palette-red-hover-subtle)",
    "variable": "var(--colors-color-palette-red-hover-subtle)"
  },
  "colors.colorPalette.decorative.red.hover-bold": {
    "value": "var(--colors-color-palette-decorative-red-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-red-hover-bold)"
  },
  "colors.colorPalette.red.hover-bold": {
    "value": "var(--colors-color-palette-red-hover-bold)",
    "variable": "var(--colors-color-palette-red-hover-bold)"
  },
  "colors.colorPalette.decorative.red.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-red-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-red-hover-bolder)"
  },
  "colors.colorPalette.red.hover-bolder": {
    "value": "var(--colors-color-palette-red-hover-bolder)",
    "variable": "var(--colors-color-palette-red-hover-bolder)"
  },
  "colors.colorPalette.decorative.orange.base-subtle": {
    "value": "var(--colors-color-palette-decorative-orange-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-orange-base-subtle)"
  },
  "colors.colorPalette.orange.base-subtle": {
    "value": "var(--colors-color-palette-orange-base-subtle)",
    "variable": "var(--colors-color-palette-orange-base-subtle)"
  },
  "colors.colorPalette.decorative.orange.base-bold": {
    "value": "var(--colors-color-palette-decorative-orange-base-bold)",
    "variable": "var(--colors-color-palette-decorative-orange-base-bold)"
  },
  "colors.colorPalette.orange.base-bold": {
    "value": "var(--colors-color-palette-orange-base-bold)",
    "variable": "var(--colors-color-palette-orange-base-bold)"
  },
  "colors.colorPalette.decorative.orange.base-bolder": {
    "value": "var(--colors-color-palette-decorative-orange-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-orange-base-bolder)"
  },
  "colors.colorPalette.orange.base-bolder": {
    "value": "var(--colors-color-palette-orange-base-bolder)",
    "variable": "var(--colors-color-palette-orange-base-bolder)"
  },
  "colors.colorPalette.decorative.orange.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-orange-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-orange-hover-subtle)"
  },
  "colors.colorPalette.orange.hover-subtle": {
    "value": "var(--colors-color-palette-orange-hover-subtle)",
    "variable": "var(--colors-color-palette-orange-hover-subtle)"
  },
  "colors.colorPalette.decorative.orange.hover-bold": {
    "value": "var(--colors-color-palette-decorative-orange-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-orange-hover-bold)"
  },
  "colors.colorPalette.orange.hover-bold": {
    "value": "var(--colors-color-palette-orange-hover-bold)",
    "variable": "var(--colors-color-palette-orange-hover-bold)"
  },
  "colors.colorPalette.decorative.orange.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-orange-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-orange-hover-bolder)"
  },
  "colors.colorPalette.orange.hover-bolder": {
    "value": "var(--colors-color-palette-orange-hover-bolder)",
    "variable": "var(--colors-color-palette-orange-hover-bolder)"
  },
  "colors.colorPalette.decorative.yellow.base-subtle": {
    "value": "var(--colors-color-palette-decorative-yellow-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-yellow-base-subtle)"
  },
  "colors.colorPalette.yellow.base-subtle": {
    "value": "var(--colors-color-palette-yellow-base-subtle)",
    "variable": "var(--colors-color-palette-yellow-base-subtle)"
  },
  "colors.colorPalette.decorative.yellow.base-bold": {
    "value": "var(--colors-color-palette-decorative-yellow-base-bold)",
    "variable": "var(--colors-color-palette-decorative-yellow-base-bold)"
  },
  "colors.colorPalette.yellow.base-bold": {
    "value": "var(--colors-color-palette-yellow-base-bold)",
    "variable": "var(--colors-color-palette-yellow-base-bold)"
  },
  "colors.colorPalette.decorative.yellow.base-bolder": {
    "value": "var(--colors-color-palette-decorative-yellow-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-yellow-base-bolder)"
  },
  "colors.colorPalette.yellow.base-bolder": {
    "value": "var(--colors-color-palette-yellow-base-bolder)",
    "variable": "var(--colors-color-palette-yellow-base-bolder)"
  },
  "colors.colorPalette.decorative.yellow.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-yellow-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-yellow-hover-subtle)"
  },
  "colors.colorPalette.yellow.hover-subtle": {
    "value": "var(--colors-color-palette-yellow-hover-subtle)",
    "variable": "var(--colors-color-palette-yellow-hover-subtle)"
  },
  "colors.colorPalette.decorative.yellow.hover-bold": {
    "value": "var(--colors-color-palette-decorative-yellow-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-yellow-hover-bold)"
  },
  "colors.colorPalette.yellow.hover-bold": {
    "value": "var(--colors-color-palette-yellow-hover-bold)",
    "variable": "var(--colors-color-palette-yellow-hover-bold)"
  },
  "colors.colorPalette.decorative.yellow.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-yellow-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-yellow-hover-bolder)"
  },
  "colors.colorPalette.yellow.hover-bolder": {
    "value": "var(--colors-color-palette-yellow-hover-bolder)",
    "variable": "var(--colors-color-palette-yellow-hover-bolder)"
  },
  "colors.colorPalette.decorative.green.base-subtle": {
    "value": "var(--colors-color-palette-decorative-green-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-green-base-subtle)"
  },
  "colors.colorPalette.green.base-subtle": {
    "value": "var(--colors-color-palette-green-base-subtle)",
    "variable": "var(--colors-color-palette-green-base-subtle)"
  },
  "colors.colorPalette.decorative.green.base-bold": {
    "value": "var(--colors-color-palette-decorative-green-base-bold)",
    "variable": "var(--colors-color-palette-decorative-green-base-bold)"
  },
  "colors.colorPalette.green.base-bold": {
    "value": "var(--colors-color-palette-green-base-bold)",
    "variable": "var(--colors-color-palette-green-base-bold)"
  },
  "colors.colorPalette.decorative.green.base-bolder": {
    "value": "var(--colors-color-palette-decorative-green-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-green-base-bolder)"
  },
  "colors.colorPalette.green.base-bolder": {
    "value": "var(--colors-color-palette-green-base-bolder)",
    "variable": "var(--colors-color-palette-green-base-bolder)"
  },
  "colors.colorPalette.decorative.green.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-green-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-green-hover-subtle)"
  },
  "colors.colorPalette.green.hover-subtle": {
    "value": "var(--colors-color-palette-green-hover-subtle)",
    "variable": "var(--colors-color-palette-green-hover-subtle)"
  },
  "colors.colorPalette.decorative.green.hover-bold": {
    "value": "var(--colors-color-palette-decorative-green-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-green-hover-bold)"
  },
  "colors.colorPalette.green.hover-bold": {
    "value": "var(--colors-color-palette-green-hover-bold)",
    "variable": "var(--colors-color-palette-green-hover-bold)"
  },
  "colors.colorPalette.decorative.green.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-green-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-green-hover-bolder)"
  },
  "colors.colorPalette.green.hover-bolder": {
    "value": "var(--colors-color-palette-green-hover-bolder)",
    "variable": "var(--colors-color-palette-green-hover-bolder)"
  },
  "colors.colorPalette.decorative.blue.base-subtle": {
    "value": "var(--colors-color-palette-decorative-blue-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-blue-base-subtle)"
  },
  "colors.colorPalette.blue.base-subtle": {
    "value": "var(--colors-color-palette-blue-base-subtle)",
    "variable": "var(--colors-color-palette-blue-base-subtle)"
  },
  "colors.colorPalette.decorative.blue.base-bold": {
    "value": "var(--colors-color-palette-decorative-blue-base-bold)",
    "variable": "var(--colors-color-palette-decorative-blue-base-bold)"
  },
  "colors.colorPalette.blue.base-bold": {
    "value": "var(--colors-color-palette-blue-base-bold)",
    "variable": "var(--colors-color-palette-blue-base-bold)"
  },
  "colors.colorPalette.decorative.blue.base-bolder": {
    "value": "var(--colors-color-palette-decorative-blue-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-blue-base-bolder)"
  },
  "colors.colorPalette.blue.base-bolder": {
    "value": "var(--colors-color-palette-blue-base-bolder)",
    "variable": "var(--colors-color-palette-blue-base-bolder)"
  },
  "colors.colorPalette.decorative.blue.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-blue-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-blue-hover-subtle)"
  },
  "colors.colorPalette.blue.hover-subtle": {
    "value": "var(--colors-color-palette-blue-hover-subtle)",
    "variable": "var(--colors-color-palette-blue-hover-subtle)"
  },
  "colors.colorPalette.decorative.blue.hover-bold": {
    "value": "var(--colors-color-palette-decorative-blue-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-blue-hover-bold)"
  },
  "colors.colorPalette.blue.hover-bold": {
    "value": "var(--colors-color-palette-blue-hover-bold)",
    "variable": "var(--colors-color-palette-blue-hover-bold)"
  },
  "colors.colorPalette.decorative.blue.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-blue-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-blue-hover-bolder)"
  },
  "colors.colorPalette.blue.hover-bolder": {
    "value": "var(--colors-color-palette-blue-hover-bolder)",
    "variable": "var(--colors-color-palette-blue-hover-bolder)"
  },
  "colors.colorPalette.decorative.purple.base-subtle": {
    "value": "var(--colors-color-palette-decorative-purple-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-purple-base-subtle)"
  },
  "colors.colorPalette.purple.base-subtle": {
    "value": "var(--colors-color-palette-purple-base-subtle)",
    "variable": "var(--colors-color-palette-purple-base-subtle)"
  },
  "colors.colorPalette.decorative.purple.base-bold": {
    "value": "var(--colors-color-palette-decorative-purple-base-bold)",
    "variable": "var(--colors-color-palette-decorative-purple-base-bold)"
  },
  "colors.colorPalette.purple.base-bold": {
    "value": "var(--colors-color-palette-purple-base-bold)",
    "variable": "var(--colors-color-palette-purple-base-bold)"
  },
  "colors.colorPalette.decorative.purple.base-bolder": {
    "value": "var(--colors-color-palette-decorative-purple-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-purple-base-bolder)"
  },
  "colors.colorPalette.purple.base-bolder": {
    "value": "var(--colors-color-palette-purple-base-bolder)",
    "variable": "var(--colors-color-palette-purple-base-bolder)"
  },
  "colors.colorPalette.decorative.purple.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-purple-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-purple-hover-subtle)"
  },
  "colors.colorPalette.purple.hover-subtle": {
    "value": "var(--colors-color-palette-purple-hover-subtle)",
    "variable": "var(--colors-color-palette-purple-hover-subtle)"
  },
  "colors.colorPalette.decorative.purple.hover-bold": {
    "value": "var(--colors-color-palette-decorative-purple-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-purple-hover-bold)"
  },
  "colors.colorPalette.purple.hover-bold": {
    "value": "var(--colors-color-palette-purple-hover-bold)",
    "variable": "var(--colors-color-palette-purple-hover-bold)"
  },
  "colors.colorPalette.decorative.purple.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-purple-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-purple-hover-bolder)"
  },
  "colors.colorPalette.purple.hover-bolder": {
    "value": "var(--colors-color-palette-purple-hover-bolder)",
    "variable": "var(--colors-color-palette-purple-hover-bolder)"
  },
  "colors.colorPalette.decorative.pink.base-subtle": {
    "value": "var(--colors-color-palette-decorative-pink-base-subtle)",
    "variable": "var(--colors-color-palette-decorative-pink-base-subtle)"
  },
  "colors.colorPalette.pink.base-subtle": {
    "value": "var(--colors-color-palette-pink-base-subtle)",
    "variable": "var(--colors-color-palette-pink-base-subtle)"
  },
  "colors.colorPalette.decorative.pink.base-bold": {
    "value": "var(--colors-color-palette-decorative-pink-base-bold)",
    "variable": "var(--colors-color-palette-decorative-pink-base-bold)"
  },
  "colors.colorPalette.pink.base-bold": {
    "value": "var(--colors-color-palette-pink-base-bold)",
    "variable": "var(--colors-color-palette-pink-base-bold)"
  },
  "colors.colorPalette.decorative.pink.base-bolder": {
    "value": "var(--colors-color-palette-decorative-pink-base-bolder)",
    "variable": "var(--colors-color-palette-decorative-pink-base-bolder)"
  },
  "colors.colorPalette.pink.base-bolder": {
    "value": "var(--colors-color-palette-pink-base-bolder)",
    "variable": "var(--colors-color-palette-pink-base-bolder)"
  },
  "colors.colorPalette.decorative.pink.hover-subtle": {
    "value": "var(--colors-color-palette-decorative-pink-hover-subtle)",
    "variable": "var(--colors-color-palette-decorative-pink-hover-subtle)"
  },
  "colors.colorPalette.pink.hover-subtle": {
    "value": "var(--colors-color-palette-pink-hover-subtle)",
    "variable": "var(--colors-color-palette-pink-hover-subtle)"
  },
  "colors.colorPalette.decorative.pink.hover-bold": {
    "value": "var(--colors-color-palette-decorative-pink-hover-bold)",
    "variable": "var(--colors-color-palette-decorative-pink-hover-bold)"
  },
  "colors.colorPalette.pink.hover-bold": {
    "value": "var(--colors-color-palette-pink-hover-bold)",
    "variable": "var(--colors-color-palette-pink-hover-bold)"
  },
  "colors.colorPalette.decorative.pink.hover-bolder": {
    "value": "var(--colors-color-palette-decorative-pink-hover-bolder)",
    "variable": "var(--colors-color-palette-decorative-pink-hover-bolder)"
  },
  "colors.colorPalette.pink.hover-bolder": {
    "value": "var(--colors-color-palette-pink-hover-bolder)",
    "variable": "var(--colors-color-palette-pink-hover-bolder)"
  },
  "colors.colorPalette.brand.subtlest": {
    "value": "var(--colors-color-palette-brand-subtlest)",
    "variable": "var(--colors-color-palette-brand-subtlest)"
  },
  "colors.colorPalette.brand.reverse-subtle": {
    "value": "var(--colors-color-palette-brand-reverse-subtle)",
    "variable": "var(--colors-color-palette-brand-reverse-subtle)"
  },
  "colors.colorPalette.reverse-subtle": {
    "value": "var(--colors-color-palette-reverse-subtle)",
    "variable": "var(--colors-color-palette-reverse-subtle)"
  },
  "colors.colorPalette.brand.reverse-bold": {
    "value": "var(--colors-color-palette-brand-reverse-bold)",
    "variable": "var(--colors-color-palette-brand-reverse-bold)"
  },
  "colors.colorPalette.reverse-bold": {
    "value": "var(--colors-color-palette-reverse-bold)",
    "variable": "var(--colors-color-palette-reverse-bold)"
  },
  "colors.colorPalette.ai": {
    "value": "var(--colors-color-palette-ai)",
    "variable": "var(--colors-color-palette-ai)"
  },
  "colors.colorPalette.ai-hover": {
    "value": "var(--colors-color-palette-ai-hover)",
    "variable": "var(--colors-color-palette-ai-hover)"
  },
  "colors.colorPalette.neutral.base.disable": {
    "value": "var(--colors-color-palette-neutral-base-disable)",
    "variable": "var(--colors-color-palette-neutral-base-disable)"
  },
  "colors.colorPalette.base.disable": {
    "value": "var(--colors-color-palette-base-disable)",
    "variable": "var(--colors-color-palette-base-disable)"
  },
  "colors.colorPalette.disable": {
    "value": "var(--colors-color-palette-disable)",
    "variable": "var(--colors-color-palette-disable)"
  },
  "colors.colorPalette.neutral.reverse.disable": {
    "value": "var(--colors-color-palette-neutral-reverse-disable)",
    "variable": "var(--colors-color-palette-neutral-reverse-disable)"
  },
  "colors.colorPalette.reverse.disable": {
    "value": "var(--colors-color-palette-reverse-disable)",
    "variable": "var(--colors-color-palette-reverse-disable)"
  },
  "colors.colorPalette.functional.error": {
    "value": "var(--colors-color-palette-functional-error)",
    "variable": "var(--colors-color-palette-functional-error)"
  },
  "colors.colorPalette.error": {
    "value": "var(--colors-color-palette-error)",
    "variable": "var(--colors-color-palette-error)"
  },
  "colors.colorPalette.functional.warning": {
    "value": "var(--colors-color-palette-functional-warning)",
    "variable": "var(--colors-color-palette-functional-warning)"
  },
  "colors.colorPalette.warning": {
    "value": "var(--colors-color-palette-warning)",
    "variable": "var(--colors-color-palette-warning)"
  },
  "colors.colorPalette.functional.sucess": {
    "value": "var(--colors-color-palette-functional-sucess)",
    "variable": "var(--colors-color-palette-functional-sucess)"
  },
  "colors.colorPalette.sucess": {
    "value": "var(--colors-color-palette-sucess)",
    "variable": "var(--colors-color-palette-sucess)"
  },
  "colors.colorPalette.functional.info": {
    "value": "var(--colors-color-palette-functional-info)",
    "variable": "var(--colors-color-palette-functional-info)"
  },
  "colors.colorPalette.info": {
    "value": "var(--colors-color-palette-info)",
    "variable": "var(--colors-color-palette-info)"
  },
  "colors.colorPalette.decorative.red": {
    "value": "var(--colors-color-palette-decorative-red)",
    "variable": "var(--colors-color-palette-decorative-red)"
  },
  "colors.colorPalette.red": {
    "value": "var(--colors-color-palette-red)",
    "variable": "var(--colors-color-palette-red)"
  },
  "colors.colorPalette.decorative.orange": {
    "value": "var(--colors-color-palette-decorative-orange)",
    "variable": "var(--colors-color-palette-decorative-orange)"
  },
  "colors.colorPalette.orange": {
    "value": "var(--colors-color-palette-orange)",
    "variable": "var(--colors-color-palette-orange)"
  },
  "colors.colorPalette.decorative.yellow": {
    "value": "var(--colors-color-palette-decorative-yellow)",
    "variable": "var(--colors-color-palette-decorative-yellow)"
  },
  "colors.colorPalette.yellow": {
    "value": "var(--colors-color-palette-yellow)",
    "variable": "var(--colors-color-palette-yellow)"
  },
  "colors.colorPalette.decorative.green": {
    "value": "var(--colors-color-palette-decorative-green)",
    "variable": "var(--colors-color-palette-decorative-green)"
  },
  "colors.colorPalette.green": {
    "value": "var(--colors-color-palette-green)",
    "variable": "var(--colors-color-palette-green)"
  },
  "colors.colorPalette.decorative.blue": {
    "value": "var(--colors-color-palette-decorative-blue)",
    "variable": "var(--colors-color-palette-decorative-blue)"
  },
  "colors.colorPalette.blue": {
    "value": "var(--colors-color-palette-blue)",
    "variable": "var(--colors-color-palette-blue)"
  },
  "colors.colorPalette.decorative.purple": {
    "value": "var(--colors-color-palette-decorative-purple)",
    "variable": "var(--colors-color-palette-decorative-purple)"
  },
  "colors.colorPalette.purple": {
    "value": "var(--colors-color-palette-purple)",
    "variable": "var(--colors-color-palette-purple)"
  },
  "colors.colorPalette.decorative.pink": {
    "value": "var(--colors-color-palette-decorative-pink)",
    "variable": "var(--colors-color-palette-decorative-pink)"
  },
  "colors.colorPalette.pink": {
    "value": "var(--colors-color-palette-pink)",
    "variable": "var(--colors-color-palette-pink)"
  },
  "colors.colorPalette.brand-subtle": {
    "value": "var(--colors-color-palette-brand-subtle)",
    "variable": "var(--colors-color-palette-brand-subtle)"
  },
  "colors.colorPalette.brand-bold": {
    "value": "var(--colors-color-palette-brand-bold)",
    "variable": "var(--colors-color-palette-brand-bold)"
  },
  "colors.colorPalette.brand-bolder": {
    "value": "var(--colors-color-palette-brand-bolder)",
    "variable": "var(--colors-color-palette-brand-bolder)"
  },
  "colors.colorPalette.brand-complementary": {
    "value": "var(--colors-color-palette-brand-complementary)",
    "variable": "var(--colors-color-palette-brand-complementary)"
  },
  "colors.colorPalette.brand-complementary-reversed": {
    "value": "var(--colors-color-palette-brand-complementary-reversed)",
    "variable": "var(--colors-color-palette-brand-complementary-reversed)"
  },
  "colors.colorPalette.neutral.subtlest": {
    "value": "var(--colors-color-palette-neutral-subtlest)",
    "variable": "var(--colors-color-palette-neutral-subtlest)"
  },
  "colors.colorPalette.neutral.subtle": {
    "value": "var(--colors-color-palette-neutral-subtle)",
    "variable": "var(--colors-color-palette-neutral-subtle)"
  },
  "colors.colorPalette.neutral.bold": {
    "value": "var(--colors-color-palette-neutral-bold)",
    "variable": "var(--colors-color-palette-neutral-bold)"
  },
  "colors.colorPalette.neutral.bold-hover": {
    "value": "var(--colors-color-palette-neutral-bold-hover)",
    "variable": "var(--colors-color-palette-neutral-bold-hover)"
  },
  "colors.colorPalette.neutral.bolder": {
    "value": "var(--colors-color-palette-neutral-bolder)",
    "variable": "var(--colors-color-palette-neutral-bolder)"
  },
  "colors.colorPalette.functional.success": {
    "value": "var(--colors-color-palette-functional-success)",
    "variable": "var(--colors-color-palette-functional-success)"
  },
  "colors.colorPalette.success": {
    "value": "var(--colors-color-palette-success)",
    "variable": "var(--colors-color-palette-success)"
  },
  "colors.colorPalette.decorative.neutral": {
    "value": "var(--colors-color-palette-decorative-neutral)",
    "variable": "var(--colors-color-palette-decorative-neutral)"
  },
  "colors.colorPalette.neutral": {
    "value": "var(--colors-color-palette-neutral)",
    "variable": "var(--colors-color-palette-neutral)"
  },
  "colors.colorPalette.brand": {
    "value": "var(--colors-color-palette-brand)",
    "variable": "var(--colors-color-palette-brand)"
  },
  "colors.colorPalette.rgb": {
    "value": "var(--colors-color-palette-rgb)",
    "variable": "var(--colors-color-palette-rgb)"
  },
  "colors.colorPalette.darker": {
    "value": "var(--colors-color-palette-darker)",
    "variable": "var(--colors-color-palette-darker)"
  },
  "colors.colorPalette.lighter": {
    "value": "var(--colors-color-palette-lighter)",
    "variable": "var(--colors-color-palette-lighter)"
  },
  "colors.colorPalette.text": {
    "value": "var(--colors-color-palette-text)",
    "variable": "var(--colors-color-palette-text)"
  },
  "colors.colorPalette.text.opposite": {
    "value": "var(--colors-color-palette-text-opposite)",
    "variable": "var(--colors-color-palette-text-opposite)"
  },
  "colors.colorPalette.opposite": {
    "value": "var(--colors-color-palette-opposite)",
    "variable": "var(--colors-color-palette-opposite)"
  },
  "colors.colorPalette.background.7": {
    "value": "var(--colors-color-palette-background-7)",
    "variable": "var(--colors-color-palette-background-7)"
  },
  "colors.colorPalette.background.10": {
    "value": "var(--colors-color-palette-background-10)",
    "variable": "var(--colors-color-palette-background-10)"
  },
  "colors.colorPalette.background.15": {
    "value": "var(--colors-color-palette-background-15)",
    "variable": "var(--colors-color-palette-background-15)"
  },
  "colors.colorPalette.background.20": {
    "value": "var(--colors-color-palette-background-20)",
    "variable": "var(--colors-color-palette-background-20)"
  },
  "colors.colorPalette.background.30": {
    "value": "var(--colors-color-palette-background-30)",
    "variable": "var(--colors-color-palette-background-30)"
  },
  "colors.colorPalette.background.100": {
    "value": "var(--colors-color-palette-background-100)",
    "variable": "var(--colors-color-palette-background-100)"
  },
  "colors.colorPalette.text.30": {
    "value": "var(--colors-color-palette-text-30)",
    "variable": "var(--colors-color-palette-text-30)"
  },
  "colors.colorPalette.text.60": {
    "value": "var(--colors-color-palette-text-60)",
    "variable": "var(--colors-color-palette-text-60)"
  },
  "colors.colorPalette.text.70": {
    "value": "var(--colors-color-palette-text-70)",
    "variable": "var(--colors-color-palette-text-70)"
  },
  "colors.colorPalette.border.20": {
    "value": "var(--colors-color-palette-border-20)",
    "variable": "var(--colors-color-palette-border-20)"
  },
  "colors.colorPalette.border": {
    "value": "var(--colors-color-palette-border)",
    "variable": "var(--colors-color-palette-border)"
  },
  "colors.colorPalette.available": {
    "value": "var(--colors-color-palette-available)",
    "variable": "var(--colors-color-palette-available)"
  },
  "colors.colorPalette.squeeze": {
    "value": "var(--colors-color-palette-squeeze)",
    "variable": "var(--colors-color-palette-squeeze)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar